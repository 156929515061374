import { tagSearch } from '../api/search';
import SearchPage from './SearchPage';

export interface Props {
    tag: string;
    pageTitle: string;
}

export default function ArticleTagSearchPage({ tag, pageTitle }: Props) {
    return <SearchPage searchType="content" pageTitle={pageTitle} search={(query) => tagSearch(tag, query)} showPrimaryCategory hideSearchControls />;
}
