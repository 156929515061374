import { trackLinkClick } from '../../../utils/trackLinkClick';
import ArticleModule, { CmsArticleListComponent } from './ArticleModule';

export default function RelatedArticlesComponent({ component }: { component: CmsArticleListComponent }) {
    if (!component.articleListComponentCards || !component.articleListComponentCards.cards) return null;
    return (
        <div className="cms-article-module" onClick={trackLinkClick} data-module="relaterte artikler">
            <ArticleModule component={component} unlimitedNumberOfCards title="Relaterte artikler" />
        </div>
    );
}
