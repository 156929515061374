import { Facet } from '../model/Facets';
import FacetButton from './FacetButton';

export interface SelectedFacetsProps {
    selectedFacets: Facet[];
    onFacetValueUnselect(query: string): void;
}

export default function SelectedFacets({ selectedFacets, onFacetValueUnselect }: SelectedFacetsProps) {
    return selectedFacets.flatMap((facet) =>
        facet.values
            .filter((v) => v.selected)
            .map((value) => (
                <FacetButton
                    key={`${facet.code}__${value.code}`}
                    title={value.name}
                    onClick={() => {
                        gtag('event', 'filter_remove', {
                            filter_type: facet.name,
                            filter_value: value.name,
                        });
                        onFacetValueUnselect(value.query);
                    }}
                    selected
                />
            )),
    );
}
