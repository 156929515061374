import { useDebounceValue } from 'usehooks-ts';

export interface Props {
    isSpinning?: boolean;
    className?: string;
}

export default function Spinner({ isSpinning = false, className = '' }: Props) {
    const [isVisible] = useDebounceValue(isSpinning, 500);
    // use isSpinning && isVisible so that the spinner
    // * appears after a 500ms delay
    // * dissapears immediately
    return isSpinning && isVisible && <span className={`spinner ${className}`} aria-hidden="true" />;
}
