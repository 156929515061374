import { DetailedHTMLProps, InputHTMLAttributes, useCallback } from 'react';
import eventTargetValue from '../../utils/eventTargetValue';
import usePropState from '../../utils/usePropState';

export interface Props extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange' | 'type'> {
    value: number;
    onChange(value: number): void;
}

export default function ForgivingNumberInput({ value, onChange, ...props }: Props) {
    const [valueString, setValueString] = usePropState(value.toString());

    const valueChanged = useCallback(
        (valueString: string) => {
            setValueString(valueString);
            const val = parseInt(valueString);
            if (!isNaN(val) && val !== value) onChange(val);
        },
        [value],
    );

    return <input type="number" {...props} value={valueString} onChange={eventTargetValue(valueChanged)} />;
}
