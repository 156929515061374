import {CmsComponent, Image} from "../../CMSComponent";
import DownloadableFileCard from "./DownloadableFileCard";

export interface DownloadableFile {
    title?: string;
    ingress?: string;
    fileDownloadUrl?: string,
    fileSizeFormatted?: string
    fileExtension?: string
    previewImage?: Image
}

export interface CmsDownloadableFileComponent extends CmsComponent {
    downloadableFile?: DownloadableFile
}

export default function CMSDownloadableFileComponent({component}: { component: CmsDownloadableFileComponent }) {
    return (
        <section className="cms-downloadable-file">
            <DownloadableFileCard file={component.downloadableFile} includeIngress={true}/>
        </section>
    );
}