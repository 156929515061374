import { Openings } from '../model/types';
import StoreTrafficTable from './StoreTrafficTable';

export interface Props {
    showTraffic?: boolean;
    openingTimes: Openings[];
}

export default function OpeningsTable({ showTraffic, openingTimes }: Props) {
    if (!openingTimes?.length) {
        return null;
    }

    return (
        <div>
            <div className="store-info__traffic">
                {openingTimes.map(({ openingDayDate, message, closed, openingTime, closingTime, timeSlotTraffic }: Openings) => (
                        <div className="day-row" key={openingDayDate.formattedDayValue}>
                            <div className="store-info__openings--date">
                                <div>{openingDayDate.formattedDayValue}</div>
                                <div>{message ?? (closed ? 'Stengt' : `${openingTime.formattedHour} - ${closingTime.formattedHour}`)}</div>
                            </div>
                            {showTraffic && (
                                <div className="store-info__openings--traffic">
                                    <StoreTrafficTable formattedDate={openingDayDate.formattedDayValue} timeSlotTraffic={timeSlotTraffic} />
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
}
