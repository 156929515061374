import Icon from '../../generic/Icon';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../ArticleInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import groq, { queryable } from '../groq';
import { RichText, richTextGroq } from '../richTextComponents';
import { BannerImage, imageWithMetadataGroq } from '../SanityImage';
import SanityPage from '../SanityPage';

interface NewsPage extends SanityPageDocument {
    _type: 'newsArticle';
    firstPublished: string;
    richText: any;
    pressContact?: {
        email: string;
        name: string;
        phone: string;
    };
}

export const newsArticle = queryable<NewsPage>(groq`
    *[_type == "newsArticle" && _id == $id][0]{
        _id,
        _type,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        firstPublished,
        richText[] ${richTextGroq},
        pressContact -> {
            email,
            name,
            phone
        }
    }
`);

export default SanityPage<NewsPage>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={2.7} />
        <section>
            <RichText value={page.richText} />
            {!!page.pressContact && (
                <address className="press-contact">
                    <h2>{page.pressContact?.name}</h2>
                    {!!page.pressContact.email && (
                        <>
                            <Icon className="icon-email" />
                            <p>
                                <a href={`mailto:${page.pressContact.email}`}>{page.pressContact.email}</a>
                            </p>
                        </>
                    )}
                    {!!page.pressContact.phone && (
                        <>
                            <Icon className="icon-phone" />
                            <p>
                                <a href={`tel:${page.pressContact.phone}`}>{page.pressContact.phone}</a>
                            </p>
                        </>
                    )}
                </address>
            )}

            <ArticleInfo firstPublished={page.firstPublished} />
        </section>
    </article>
));
