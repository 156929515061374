import { CSSProperties } from 'react';

export function getStyleAttributeObject(cssText: string | undefined): CSSProperties | undefined {
    if (cssText) {
        const regex = /([\w-]*)\s*:\s*([^;]*)/g;
        const properties: Record<string, string> = {};
        let match;
        while ((match = regex.exec(cssText))) properties[match[1]] = match[2].trim();
        return properties;
    }
}
