import articleFallbackImage from '../../images/article_fallback_image.png';

export interface Props {
    article: Article;
    showPrimaryCategory?: boolean;
}

export interface Article {
    label: string;
    listImageUrl?: string;
    title: string;
    primaryCategoryName: string;
}

export default function ArticleCard({ article, showPrimaryCategory = true }: Props) {
    const gotoArticle = () => {
        location.href = article.label;
    };

    return (
        <article className="article-card" onClick={gotoArticle}>
            <div className="article-card__image-container">
                <a href={article.label} tabIndex={-1}>
                    <img alt={article.title} src={article.listImageUrl || articleFallbackImage} />
                </a>
            </div>
            <a className="article-card__info button" href={article.label}>
                {showPrimaryCategory && <div className="article-card__category">{article.primaryCategoryName}</div>}
                <h2>{article.title}</h2>
            </a>
        </article>
    );
}
