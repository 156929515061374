import cn from 'classnames';
import { trackLinkClick } from '../../../utils/trackLinkClick';
import { CmsComponent, Image } from '../../CMSComponent';

export interface CmsThemeModuleComponent extends CmsComponent {
    imageOrientation?: string;
    title?: string;
    ingress?: string;
    image?: Image;
    url?: string;
    buttonText?: string;
}

export default function ThemeModuleComponent({ component }: { component: CmsThemeModuleComponent }) {
    const render = component.title && component.ingress && component.image?.url;

    if (!render) return null;

    return (
        <section
            data-module={component.name}
            className={cn('theme-module', component.imageOrientation === 'RIGHT' ? 'imageOnRight' : 'imageOnLeft')}
            onClick={trackLinkClick}
        >
            <a className="theme-module__link button" href={component.url}>
                <div className="theme-module__image">
                    <img src={component.image?.url} alt={component.title} loading="lazy" />
                </div>
            </a>
            <div className="theme-module__content">
                <a className="theme-module__content-link button" href={component.url}>
                    <h2 className="theme-module__title">{component.title}</h2>
                    {component.ingress && <div className="theme-module__text" dangerouslySetInnerHTML={{ __html: component.ingress }} />}
                    <div className="theme-module-link">{component.buttonText}</div>
                </a>
            </div>
        </section>
    );
}
