import { trackLinkClick } from '../../../utils/trackLinkClick';
import ArticleModule, { CmsArticleListComponent } from './ArticleModule';

export default function ArticleListComponent({ component }: { component: CmsArticleListComponent }) {
    return (
        <div className="cms-article-module" data-module={component.name} onClick={trackLinkClick}>
            <ArticleModule component={component} unlimitedNumberOfCards />
        </div>
    );
}
