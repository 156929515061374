import {CmsParagraphComponent} from "./CMSParagraphComponent";

export interface CmsFactsBoxComponent extends CmsParagraphComponent {
    title?: string,
}

export default function CMSFactsBoxComponent({component}: { component: CmsFactsBoxComponent }) {
    return (
        <div className="cms-facts-box">
            {component.title && <h2>{component.title}</h2>}
            {component.sanitizedContent && <div className="content" dangerouslySetInnerHTML={{__html: component.sanitizedContent}}/>}
        </div>
    );
}