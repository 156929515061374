import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { SmarteditProperties } from './PageData';
import CMSDownloadableFileComponent from './components/articlebody/CMSDownloadableFileComponent';
import CMSFactsBoxComponent from './components/articlebody/CMSFactsBoxComponent';
import CMSHighlightedTextComponent from './components/articlebody/CMSHighlightedTextComponent';
import CMSImageComponent from './components/articlebody/CMSImageComponent';
import CMSImageTextComponent from './components/articlebody/CMSImageTextComponent';
import CMSParagraphComponent from './components/articlebody/CMSParagraphComponent';
import CMSPodcastComponent from './components/articlebody/CMSPodcastComponent';
import ArticleListComponent from './components/articlelist/ArticleListComponent';
import ArticleModuleComponent from './components/articlelist/ArticleModuleComponent';
import RelatedArticlesComponent from './components/articlelist/RelatedArticlesComponent';
import CMSIconLinkComponent from './components/link/CMSIconLinkComponent';
import CMSLinkComponent from './components/link/CMSLinkComponent';
import CMSServiceItemComponent from './components/link/CMSServiceItemComponent';
import CMSServicesComponent, { CmsServicesComponent } from './components/link/CMSServicesComponent';
import TopModuleComponent, { CmsTopModuleComponent } from './components/link/TopModuleComponent';
import CMSDownloadableFileListComponent from './components/other/CMSDownloadableFileListComponent';
import ThemeModuleComponent from './components/other/ThemeModuleComponent';
import UspBar from './components/other/UspBar';

const NORWEGIAN_DATE_FORMATTER = new Intl.DateTimeFormat('nb-NO', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'Europe/Oslo',
});

const SHORT_NORWEGIAN_DATE_FORMATTER = new Intl.DateTimeFormat('nb-NO', {
    month: 'short',
    year: 'numeric',
    timeZone: 'Europe/Oslo',
});

export interface CmsLinkComponent extends CmsComponent {
    url?: string;
    target?: string;
    linkName?: string;
    description?: string;
    styleClasses?: string;
    styleAttributes?: string;
}

export interface CmsNavigationNode {
    children?: {
        uid: string;
        links?: CmsLinkComponent[];
    }[];
}

export interface CmsNavigationNodeEntry {
    uid: string;
    uuid: string;
    title?: string;
    links?: CmsComponent[];
}

export interface CmsComponent {
    uid: string;
    typeCode: string;
    name: string;
    otherProperties?: {
        key: string;
        value: { type: 'boolean'; value: boolean } | { type: 'string'; value: string };
    }[];
    navigationNode?: CmsNavigationNode;
    navigationNodes?: CmsNavigationNodeEntry[];
    components?: CmsComponent[] | CmsComponent;
    properties?: {
        smartedit?: SmarteditProperties;
    };
}

export interface Image {
    altText: string;
    url: string;
}

export interface Props<T extends CmsComponent> {
    component: T;
    className?: string;
    includeSmarteditProperties?: boolean;
}

export function getFormattedDate(dateText: string | undefined, type: 'long' | 'short' = 'long'): string | undefined {
    if (dateText) {
        return type === 'long' ? NORWEGIAN_DATE_FORMATTER.format(new Date(dateText)) : SHORT_NORWEGIAN_DATE_FORMATTER.format(new Date(dateText));
    }
}

function renderCMSComponent<T extends CmsComponent>(component: T) {
    switch (component.typeCode) {
        case 'CMSLinkComponent':
            return <CMSLinkComponent component={component} />;
        case 'CMSIconLinkComponent':
            return <CMSIconLinkComponent component={component} />;
        case 'CMSServiceItemComponent':
            return <CMSServiceItemComponent component={component} />;
        case 'CMSServicesComponent':
            return <CMSServicesComponent component={component as CmsServicesComponent} />;
        case 'TopModuleComponent':
            return <TopModuleComponent component={component as CmsTopModuleComponent} />;
        case 'ArticleListComponent':
            return <ArticleListComponent component={component} />;
        case 'ArticleModuleComponent':
            return <ArticleModuleComponent component={component} />;
        case 'ThemeModuleComponent':
            return <ThemeModuleComponent component={component} />;
        case 'JspIncludeComponent':
            return component.uid === 'uspBar' ? (
                <UspBar />
            ) : (
                <div style={{ display: 'none' }}>JspIncludeComponent {component.uid} must be implemented</div>
            );
        case 'CMSParagraphComponent':
            return <CMSParagraphComponent component={component} />;
        case 'CMSFactsBoxComponent':
            return <CMSFactsBoxComponent component={component} />;
        case 'CMSImageComponent':
            return <CMSImageComponent component={component} />;
        case 'CMSImageTextComponent':
            return <CMSImageTextComponent component={component} />;
        case 'CMSPodcastComponent':
            return <CMSPodcastComponent component={component} />;
        case 'CMSHighlightedTextComponent':
            return <CMSHighlightedTextComponent component={component} />;
        case 'CMSDownloadableFileComponent':
            return <CMSDownloadableFileComponent component={component} />;
        case 'RelatedArticlesComponent':
            return <RelatedArticlesComponent component={component} />;
        case 'CMSDownloadableFileListComponent':
            return <CMSDownloadableFileListComponent component={component} />;
        default:
            return <div style={{ display: 'none' }}>Component {component.typeCode} must be implemented</div>;
    }
}

export default function CMSComponent<T extends CmsComponent>({ component, className, includeSmarteditProperties }: Props<T>) {
    return (
        <SmartEditDiv className={className} smartedit={includeSmarteditProperties && component.properties?.smartedit}>
            {renderCMSComponent(component)}
        </SmartEditDiv>
    );
}

export function SmartEditDiv({ smartedit, className, children }: PropsWithChildren<{ className?: string; smartedit?: SmarteditProperties | false }>) {
    return smartedit ? (
        <div
            className={cn(smartedit.classes, className)}
            data-smartedit-component-id={smartedit.componentId}
            data-smartedit-catalog-version-uuid={smartedit.catalogVersionUuid}
            data-smartedit-component-type={smartedit.componentType}
            data-smartedit-component-uuid={smartedit.componentUuid}
        >
            {children}
        </div>
    ) : className ? (
        <div className={className}>{children}</div>
    ) : (
        <>{children}</>
    );
}
