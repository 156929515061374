export interface TimeSlotTraffic {
    trafficColor: string;
    timeSlotStart: number;
    timeSlotEnd: number;
    trafficText: string;
}

export interface Props {
    timeSlotTraffic?: TimeSlotTraffic[];
    formattedDate: string;
}

export default function StoreTrafficTable({ timeSlotTraffic, formattedDate }: Props) {
    if (!timeSlotTraffic) {
        return null;
    }

    return (
        <div className="timeslots" aria-labelledby={`heading-${formattedDate}-traffic`}>
            {timeSlotTraffic.map(({ trafficColor, timeSlotStart, timeSlotEnd, trafficText }) => (
                <div className="timeslot" key={`${timeSlotStart}-${timeSlotEnd}`}>
                    <div className={`color ${trafficColor.toLowerCase()}`} />
                    <div className="interval interval-desc">{trafficText}</div>
                    <div className="interval">{`${timeSlotStart} - ${timeSlotEnd}`}</div>
                </div>
            ))}
        </div>
    );
}
