import { useContext } from 'react';
import { DataAttributeContext } from './DataAttributeContext';
import fallbackImage from './fallback.svg';
import SanityImage, { ImageWithMetadata } from './SanityImage';

const ImageWidths = [290, 290 * 2, 535, 535 * 2, 735, 735 * 2];

interface Props {
    image: ImageWithMetadata;
    sanityImageName?: string;
}

export default function GridItemImage({ image, sanityImageName = 'bannerImage' }: Props) {
    const dataAttribute = useContext(DataAttributeContext);

    if (!image) {
        return (
            <picture>
                <img src={fallbackImage} style={{ aspectRatio: 16 / 9 }} />
            </picture>
        );
    }

    return (
        <SanityImage
            image={image}
            sizes="(min-width: 768px) 290px, calc(100% - 2 * 16px)"
            widths={ImageWidths}
            aspectRatio={16 / 9}
            data-sanity={dataAttribute?.(sanityImageName)}
        />
    );
}
