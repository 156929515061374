import { Dispatch, SetStateAction, useMemo, useState } from 'react';

export default function useStateWithSideEffect<T = undefined>(
    effect: (value: T | undefined) => void,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>];
export default function useStateWithSideEffect<T>(initialState: T | (() => T), effect: (value: T) => void): [T, Dispatch<SetStateAction<T>>];
export default function useStateWithSideEffect<T>(...args: [T | (() => T), (value: T) => void] | [(value: T | undefined) => void]) {
    if (args.length === 2) {
        const effect = args[1];

        const [state, setState] = useState(args[0]);

        const setValueWithSideEffect = useMemo(() => withSideEffect(setState, effect), []);

        return [state, setValueWithSideEffect];
    } else {
        const effect = args[0];

        const [state, setState] = useState<T>();

        const setValueWithSideEffect = useMemo(() => withSideEffect(setState, effect), []);

        return [state, setValueWithSideEffect];
    }
}

function withSideEffect<T>(setState: Dispatch<SetStateAction<T>>, effect: (value: T) => void): Dispatch<SetStateAction<T>> {
    return (value: SetStateAction<T>) => {
        if (isReducer(value)) {
            setState((prev) => {
                const next = value(prev);
                effect(next);
                return next;
            });
        } else {
            setState(value);
            effect(value);
        }
    };
}

function isReducer<T>(action: SetStateAction<T>): action is (prevState: T) => T {
    return typeof action === 'function';
}
