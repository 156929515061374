import CMSComponent, {CmsComponent} from "../../CMSComponent";
import cn from 'classnames';
import {CmsImageComponent} from "./CMSImageComponent";

export interface CmsImageTextComponent extends CmsComponent {
    image?: CmsImageComponent,
    imageOrientation?: string,
    content?: string,
    title?: string
}

export default function CMSImageTextComponent({component}: { component: CmsImageTextComponent }) {
    return (
        <>
            <div
                className={cn('cms-image-text', component.imageOrientation === 'RIGHT' ? 'imageOnRight' : 'imageOnLeft', {'noImage': !component.image})}>
                {component.image && <div className="imagePart">
                    <CMSComponent component={component.image}/>
                </div>}
                <div className="textPart">
                    {component.title && <h2 className="title">{component.title}</h2>}
                    {component.content && <div className="content" dangerouslySetInnerHTML={{__html: component.content}}/>}
                </div>
            </div>
            {component.image && component.image.photocredit &&
                 <div className="article__image-credits" dangerouslySetInnerHTML={{ __html: component.image.photocredit }}/>
            }
        </>
    );
}