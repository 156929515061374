import {CmsComponent} from "../../CMSComponent";
import {DownloadableFile} from "../articlebody/CMSDownloadableFileComponent";
import cn from 'classnames';
import DownloadableFileCard from "../articlebody/DownloadableFileCard";

export interface CmsDownloadableFileListComponent extends CmsComponent {
    downloadableFileList?: {
        files: DownloadableFile[];
    };
    title?: string,
}

export default function CMSDownloadableFileListComponent({component}: { component: CmsDownloadableFileListComponent }) {
    if (!component.downloadableFileList || !component.downloadableFileList.files) return null;
    return (
        <>
            {component.title &&
                <div className="heading-separator section-heading">
                    <span className="heading-separator--line"/>
                    <h2>{component.title}</h2>
                    <span className="heading-separator--line"/>
                </div>
            }
            <div className="cms-article-module">
                <section className="article-module">
                    <div
                        className={cn('cms-downloadable-file--list', 'article-module__items', {'article-module__items--title': component.title})}>
                        {component.downloadableFileList.files.map((file) => (
                            <article className="article-card" key={file.fileDownloadUrl}>
                                <DownloadableFileCard file={file} includeIngress={false}/>
                            </article>
                        ))}
                    </div>
                </section>
            </div>
        </>
    )
}