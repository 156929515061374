import {Image} from "../../CMSComponent";
import cn from 'classnames';
import articleFallbackImage from '../../../../images/article_fallback_image.png';

export interface CmsArticleCard {
    title?: string,
    image?: Image,
    url?: string,
    category?: string,
    ingress?: string,
}

interface Props {
    card: CmsArticleCard;
    className?: string,
    includeIngress?: boolean,
}

export default function ArticleCard({card, className, includeIngress}: Props) {
    const imageUrl = card.image && card.image.url ? card.image.url : articleFallbackImage;
    return (
        <article className={cn('article-card', className)}>
            <div className="article-card__image-container">
                <a href={card.url} tabIndex={-1}>
                    <img alt={card.title} src={imageUrl} loading="lazy"/>
                </a>
            </div>
            <a className="article-card__info button" href={card.url}>
                {card.category ? <div className="article-card__category">{card.category}</div>
                    : <div className="article-card__category empty"/>}
                <h2>{card.title}</h2>
                {includeIngress && <p className="article-module__item__ingress">{card.ingress}</p>}
            </a>
        </article>);
}
