import Page from "../generic/Page";

export default function NotFoundPage() {
    return (
        <Page title="Siden finnes ikke">
            <div className="informative-page__content">
                <div className="informative-page__icon link__icon icon icon-stacking-bottles" />
                <div className="informative-page__text">
                    <p>
                        Gå til <a href="/">forsiden</a>
                    </p>
                    <p>Bruk navigasjonen i toppen</p>
                    <p>Gjør et nytt søk</p>
                    <p className='text-emphasized'>Kontakt oss</p>
                    <p>E-post: <a href='mailto:kundesenter@vinmonopolet.no'>kundesenter@vinmonopolet.no</a></p>
                    <p>Telefon: <a href='tel:22015000'>22 01 50 00</a></p>
                </div>
            </div>
        </Page>
    );
}