import { QueryParams, QueryWithoutParams, SanityDocument } from '@sanity/client';

const groq = String.raw;

export default groq;

declare const response: unique symbol;
declare const params: unique symbol;

export type GroqString<R extends SanityDocument & { _type: string }, Q extends QueryWithoutParams | QueryParams = QueryWithoutParams> = string & {
    [response]: R;
    [params]: Q;
};

export type QueryOf<Q extends GroqString<any, any>> = Q extends GroqString<infer R, any> ? R : never;
export type ParamsOf<Q extends GroqString<any, any>> = Q extends GroqString<any, infer P> ? P : never;

export function queryable<R extends SanityDocument, Q extends QueryWithoutParams | QueryParams = { id: string }>(query: string) {
    return query as GroqString<R, Q>;
}
