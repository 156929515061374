import { ReactPageFunction } from '../js/cloudflare/types';
import { hybrisContent, HybrisPage, HybrisPageProps, HybrisStaticLayout } from '../js/hybrisContent';
import { StaticLayoutProps } from '../js/layout/HeaderFooterLayout';
import SanityPage, { sanityContent, SanityPageProps, SanityStaticLayout } from '../js/sanity/page';

type Props = HybrisPageProps | SanityPageProps;

export const onRequestGet: ReactPageFunction<'/[[slug]]', Props> = async (ctx) => {
    const url = new URL(ctx.request.url);

    // Try to get the content from sanity, if that returns undefined then go to hybris.
    return (await sanityContent(url, ctx)) ?? (await hybrisContent(url, ctx));
};

export default function Page(props: Props) {
    if (isSanityPage(props)) {
        return <SanityPage {...props} />;
    } else {
        return <HybrisPage {...props} />;
    }
}

export function StaticLayout({ assets, props, url }: StaticLayoutProps<Props>) {
    if (isSanityPage(props)) {
        return <SanityStaticLayout props={props} url={url} assets={assets} />;
    } else {
        return <HybrisStaticLayout props={props} url={url} assets={assets} />;
    }
}

function isSanityPage(props: Props) {
    return !!props._type;
}
