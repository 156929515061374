import {CmsComponent} from "../../CMSComponent";

export interface CmsParagraphComponent extends CmsComponent {
    sanitizedContent?: string,
}

export default function CMSParagraphComponent({component}: { component: CmsParagraphComponent }) {
    if (!component.sanitizedContent) return null;
    return (
        <div className="content" dangerouslySetInnerHTML={{__html: component.sanitizedContent}}/>
    );
}