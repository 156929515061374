import { PortableTextBlock, PortableTextComponentProps } from '@portabletext/react';
import { useContext } from 'react';
import { DataAttributeContext } from '../DataAttributeContext';
import groq from '../groq';
import { SimpleRichText } from '../richTextComponents';
import { markDefsGroq } from './markDefsGroq';

interface FactBox {
    _key: string;
    title: string;
    simpleRichText: PortableTextBlock;
}

export const factBoxGroq = groq`
{
        title,
        simpleRichText[]
        {
            ...,
            markDefs[] ${markDefsGroq},
        },
    }`;

export function FactBox({ value }: PortableTextComponentProps<FactBox>) {
    const attr = useContext(DataAttributeContext);
    return (
        <aside className="fact-box" data-sanity={attr?.([{ _key: value._key }])}>
            <h2>{value.title}</h2>
            <SimpleRichText value={value.simpleRichText} />
        </aside>
    );
}
