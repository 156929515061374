import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../ArticleInfo';
import ArticleTags from '../components/ArticleTags';
import Breadcrumbs from '../components/Breadcrumbs';
import groq, { queryable } from '../groq';
import { RichText, richTextGroq } from '../richTextComponents';
import { BannerImage, imageWithMetadataGroq } from '../SanityImage';
import SanityPage from '../SanityPage';

interface VintageArticle extends SanityPageDocument {
    _type: 'vintageArticle';
    richText: any;
}

export const vintageArticle = queryable<VintageArticle>(groq`
    *[_type == "vintageArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        richText[] ${richTextGroq},
    }
`);

export default SanityPage<VintageArticle>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={2.7} />
        <ArticleTags tags={page.tags} />
        <section>
            <RichText value={page.richText} />
            <ArticleInfo lastUpdated={page._updatedAt} />
        </section>
    </article>
));
