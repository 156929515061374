import {CmsComponent, Image} from "../../CMSComponent";

export interface CmsImageComponent extends CmsComponent {
    media?: Image,
    photocredit?: string
}

export default function CMSImageComponent({component}: { component: CmsImageComponent }) {
    if (!component.media) return null;
    return (
        <div className="cmsimage">
            <img title={component.media.altText} src={component.media.url} alt={component.media.altText} loading="lazy" />
            {component.photocredit &&  <div className="article__image-credits" dangerouslySetInnerHTML={{ __html: component.photocredit }}/>}
        </div>
    );
}