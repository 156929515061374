import cn from 'classnames';
import { PropsWithChildren } from 'react';
import ErrorBox from './ErrorBox';
import PageHeading from './PageHeading';
import SpinnerContainer from './SpinnerContainer';

export interface Props {
    title?: string;
    subTitle?: string;
    backUrl?: string;
    isSpinning?: boolean;
    error?: any;
    className?: string;
}

export default function Page({ title, subTitle, backUrl, isSpinning = false, error, className, children }: PropsWithChildren<Props>) {
    return (
        <div className={cn('section', className)}>
            <div className="section__inner-container">
                {title && <PageHeading heading={title} subHeading={subTitle} backHref={backUrl} />}
                <SpinnerContainer isSpinning={isSpinning}>
                    <ErrorBox errors={error} />
                    {children}
                </SpinnerContainer>
            </div>
        </div>
    );
}
