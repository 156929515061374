import { PropsWithChildren, useContext, useEffect } from 'react';
import { consentVerificationURL, emailVerificationURL } from '../config/SiteConfig';
import { UserContext } from '../login/userContext';
import { handleNotAuthenticated } from './auth';
import useBooleanState from './useBooleanState';

export default function Authenticated({ children }: PropsWithChildren) {
    const { isLoggedIn, user } = useContext(UserContext);
    const [showPage, setShowPage] = useBooleanState();

    useEffect(() => {
        // isLoggedIn is undefined on the server and during initial clientside load
        if (isLoggedIn === false) {
            handleNotAuthenticated();
        } else if (isLoggedIn) {
            const redirectUrl =
                shouldRedirectTo(user.requiresConsent, consentVerificationURL()) ||
                shouldRedirectTo(user.requiresEmailVerification, emailVerificationURL());

            if (redirectUrl) {
                location.assign(redirectUrl);
            } else {
                setShowPage.toTrue();
            }
        }
    }, [isLoggedIn]);

    return showPage ? <>{children}</> : null;
}

function shouldRedirectTo(condition: boolean, url: string) {
    if (condition && location.pathname != url) {
        return `${url}?redirect=${location.pathname}${location.search}`;
    } else {
        return false;
    }
}
