import { useEffect, useState } from 'react';
import { Article, NavigationalElement } from '../cms/ArticleHeader';
import { getArray } from '../cms/index';

export interface Props {
    article: Article;
}
export default function StructuredArticleData({ article: { title, ingress, articleBannerImage, ...article } }: Props) {
    const [structuredData, setStructuredData] = useState<string|null>("");
    useEffect(() => {
        setStructuredData(getArticleStructuredData({ title, articleBannerImage, ingress, ...article }, getArray(article.tags?.elements)))
    },[article])

    return (
        !!structuredData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} />
    );
}

function getArticleStructuredData(article: Article, tags: NavigationalElement[]) {
    try {
        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            "headline": article?.title ?? '',
            "description": article?.ingress ?? '',
            "image": article?.articleBannerImage?.url ?? '',
            "url": window.location.href,
            "keywords": tags.map((element) => element.name)
        });
    } catch (e) {
        console.error(e);
        return null;
    }
}