import { useCallback, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import Icon from '../generic/Icon';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';

interface Props {
    onSearch(input: string): void;
    onItemSelected?: (suggestion: string) => void;
    autocomplete(input: string): Promise<Suggestion[]>;
}

interface Suggestion {
    label: string;
    value: string;
}

export default function SearchBox({ onSearch, onItemSelected, autocomplete }: Props) {
    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);


    const getSuggestions = useDebounceCallback(
        useCallback((value: string) => autocomplete(value).then(setSuggestions), []),
        300,
    );

    const onChange = (value: string) => {
        setInput(value);
        if (value.length < 3) {
            setSuggestions([]);
        } else {
            getSuggestions(value);
        }
    };

    const onSubmit = () => {
        setSuggestions([]);
        setActiveSuggestionIndex(-1);

        const suggestion = suggestions[activeSuggestionIndex];

        if (suggestion && onItemSelected) {
            onItemSelected(suggestion.value);
            gtag('event', 'search_store', {
                search_term: suggestion.label,
                search_suggestion: true,
            });
        } else if (input) {
            onSearch(input);
            gtag('event', 'search_store', {
                search_term: input,
                search_suggestion: false,
            });
        }
    };

    const onClickSuggestion = ({ label, value }: Suggestion) => {
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
        setInput(label);

        gtag('event', 'search_store', {
            search_term: label,
            search_suggestion: true,
        });

        if (onItemSelected) {
            onItemSelected(value);
        } else {
            onSearch(label);
        }
    };

    const onKeyDown = (eventKey: string) => {
        if (eventKey === 'ArrowUp' && activeSuggestionIndex > 0) {
            autocompleteNav(-1);
        } else if (eventKey === 'ArrowDown' && activeSuggestionIndex + 1 < suggestions.length) {
            autocompleteNav(+1);
        }
    };

    const autocompleteNav = (direction: number) => {
        setActiveSuggestionIndex(activeSuggestionIndex + direction);
        setInput(suggestions[activeSuggestionIndex + direction].label ?? '');
    };

    return (
        <form className="search-form" onSubmit={preventDefault(onSubmit)}>
            <button className="search-button" type="submit" tabIndex={-1} aria-label="Søk etter butikk">
                <Icon className="icon-global-search" />
            </button>
            <input
                className="form-control"
                type="text"
                onChange={eventTargetValue(onChange)}
                autoFocus
                onKeyDown={(e) => onKeyDown(e.key)}
                value={input}
                placeholder="Søk etter butikk"
            />
            {!!suggestions.length && (
                <ul className="autocomplete">
                    {suggestions.map((suggestion, index) => (
                        <li
                            className={index === activeSuggestionIndex ? 'suggestion-active' : ''}
                            key={suggestion.value}
                            onClick={() => onClickSuggestion(suggestion)}
                            tabIndex={-1}
                        >
                            {suggestion.label}
                        </li>
                    ))}
                </ul>
            )}
        </form>
    );
}
