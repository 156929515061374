import articleFallbackImageSquare from '../../../../images/article_fallback_image_square.png';
import Icon from "../../../generic/Icon";
import { DownloadableFile } from "./CMSDownloadableFileComponent";

interface Props {
    file?: DownloadableFile;
    includeIngress?: boolean,
}

export default function DownloadableFileCard({file, includeIngress}: Props) {
    if (!file) return null;
    return (
        <div className="image-text-component-row">
            <div className="cms-downloadable-file-img">
                <a className="link--no-border downloadable-file-link--hover" href={file.fileDownloadUrl}
                   tabIndex={-1}/>
                <img alt={file.title}
                     src={file.previewImage?.url ? file.previewImage.url : articleFallbackImageSquare}/>
            </div>
            <a className="downloadable-file-info link--no-border" href={file.fileDownloadUrl} aria-label={`Last ned ${file.title} ${file.fileExtension} ${file.fileSizeFormatted}`}>
                <div className="downloadable-file-info-wrapper">
                    <h3><span>{file.title}</span></h3>
                    {includeIngress && file.ingress && <div dangerouslySetInnerHTML={{__html: file.ingress}}/>}
                    <div className="downloadable-file-info-link">
                        <span>{file.fileExtension} {file.fileSizeFormatted}</span>
                        <Icon className='icon-download'/>
                    </div>
                </div>
            </a>
        </div>
    );
}
