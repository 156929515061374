import classNames from 'classnames';
import Icon from '../generic/Icon';
import * as style from './FacetButton.module.less';

export interface Props {
    title: string;
    selected?: boolean;
    selectable?: boolean;
    onClick(): void;
}

export default function FacetButton({ title, selected = false, selectable = false, onClick }: Props) {
    return (
        <button className={classNames(style.facetButton, { [style.selected]: selected, [style.selectable]: selectable })} onClick={onClick}>
            <span>{title}</span>
            {selected && <Icon className="icon-xmark white" />}
        </button>
    );
}
