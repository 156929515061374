interface Props {
    productCode: string;
    rating: number;
    onSelectStar(rating: number): void;
}

export default function ReviewStars({ productCode, rating, onSelectStar }: Props) {
    const onChange = (newRating: number) => {
        if (newRating === rating) {
            onSelectStar(0.0);
        } else onSelectStar(newRating);
    };
    return (
        <div className="product-item__rating-stars" role="radiogroup" aria-labelledby={`${productCode}_label`}>
            <span id={`${productCode}_label`} className="sr-only">
                Gi produkt opp til 5 stjerner.
            </span>
            <Star stars={5} productCode={productCode} rating={rating} onChange={onChange} />
            <Star stars={4} productCode={productCode} rating={rating} onChange={onChange} />
            <Star stars={3} productCode={productCode} rating={rating} onChange={onChange} />
            <Star stars={2} productCode={productCode} rating={rating} onChange={onChange} />
            <Star stars={1} productCode={productCode} rating={rating} onChange={onChange} />
        </div>
    );
}

function Star({ stars, productCode, rating, onChange }: { stars: number; productCode: string; rating: number; onChange: (rating: number) => void }) {
    return (
        <>
            <input
                tabIndex={0}
                type="radio"
                id={`${productCode}_star${stars}`}
                aria-label={`${stars} stjerner`}
                name="rating"
                value={stars}
                checked={stars == rating}
                onChange={() => onChange(stars)}
            />
            <label className="product-item__star" htmlFor={`${productCode}_star${stars}`} />
        </>
    );
}
