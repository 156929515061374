import { SanityDocument } from '@sanity/client';
import { useContext } from 'react';
import Icon from '../generic/Icon';
import prettyBytes from '../utils/prettyBytes';
import * as styles from './Brochure.module.less';
import { DataAttributeContext } from './DataAttributeContext';
import SanityImage, { imageWithMetadataGroq } from './SanityImage';

export type BrochureProps = SanityDocument & {
    title: string;
    _type: 'brochure';
    pdf: {
        url: string;
        size: number;
        extension: string;
    };
};

export const brochuresGroq = /* groq */ ` {
    _id,
    _type,
    title,
    image ${imageWithMetadataGroq},
    "pdf": pdf.asset->{
        extension,
        size,
        url
    }
}`;

const imageWidths = [90, 90 * 2, 242, 242 * 2];

export function Brochure({ title, pdf, image }: BrochureProps) {
    const dataAttribute = useContext(DataAttributeContext);
    return (
        <li className={styles.brochure}>
            <a
                href={`${pdf?.url}?dl=`}
                className="link--no-border"
                aria-label={`Last ned brosjyren: ${title} (${pdf?.extension}, ${prettyBytes(pdf?.size ?? 0)})`}
            >
                <SanityImage
                    image={image}
                    widths={imageWidths}
                    sizes="(min-width: 568px) 242px, 90px"
                    data-sanity={dataAttribute?.('image')}
                    aspectRatio={1.04}
                    loading="lazy"
                />

                <div className={styles.info}>
                    <h2>{title}</h2>
                    <span data-sanity={dataAttribute?.('pdf')}>
                        {pdf?.extension} {prettyBytes(pdf?.size ?? 0)}
                        <Icon className="icon-download" />
                    </span>
                </div>
            </a>
        </li>
    );
}
