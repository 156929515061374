import cn from 'classnames';
import { getStyleAttributeObject } from '../../../generic/componentUtils';
import { trackLinkClick } from '../../../utils/trackLinkClick';
import CMSComponent, { CmsComponent, CmsLinkComponent, Image } from '../../CMSComponent';

export interface CmsTopModuleComponent extends CmsComponent {
    components?: CmsLinkComponent[];
    styleClasses?: string;
    backgroundImage?: Image;
    subtitle?: string;
    title?: string;
}

export default function CmsTopModuleComponent({ component }: { component: CmsTopModuleComponent }) {
    return (
        <div
            style={component.backgroundImage?.url ? getStyleAttributeObject(`backgroundImage:url(${component.backgroundImage.url})`) : undefined}
            className={cn('top-module__banner', component.styleClasses)}
            onClick={trackLinkClick}
            data-module="top_module"
        >
            <div className="top-module__line" />
            <div className="top-module__line--thin" />
            <div className={cn('top-module__container', `top-module__container--${component.components?.length}`)}>
                {component.subtitle && <h5 className="top-module__subtitle">{component.subtitle}</h5>}
                {component.title && <h2 className="top-module__title">{component.title}</h2>}
                {component.components && (
                    <div className={cn('top-module__links', `top-module__links--${component.components.length}`)}>
                        {component.components.map((link) => (
                            <CMSComponent key={link.uid} component={link} className="top-module__link" />
                        ))}
                    </div>
                )}
            </div>
            <div className="top-module__line--thin" />
            <div className="top-module__line" />
        </div>
    );
}
