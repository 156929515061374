export interface Props {
    text?: string;
    sr?: string;
    srPrefix?: string;
}

export default function AltSR({ text, sr, srPrefix = '' }: Props) {
    return (
        <>
            <span className="sr-only">{sr || srPrefix}</span>
            <span aria-hidden={!srPrefix}>{text}</span>
            <span className="sr-only">.</span>
        </>
    );
}
