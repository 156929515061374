import ArticleCard, { Article } from './ArticleCard';

interface Props {
    articles: Article[];
    showPrimaryCategory?: boolean;
    numberOfArticlesWidth: number;
}

export default function ContentList({ articles, showPrimaryCategory, numberOfArticlesWidth }: Props) {
    return (
        <ul className={`article-grid row-max-${numberOfArticlesWidth}`}>
            {articles.map((article) => (
                <ArticleCard key={article.label} article={article} showPrimaryCategory={showPrimaryCategory} />
            ))}
        </ul>
    );
}
