import { PortableTextComponentProps } from '@portabletext/react';
import { stegaClean } from '@sanity/client/stega';
import groq from '../groq';
import { CaptionedSanityImage, ImageWithMetadata, imageWithMetadataGroq } from '../SanityImage';
import FileComponent, { fileComponentGroq, FileComponentProps } from './fileComponent';

export type FloatProps = {
    side: 'left' | 'right';
    _key: string;
} & (
    | {
          type: 'imageWithMetadata';
          imageWithMetadata: ImageWithMetadata;
      }
    | {
          type: 'fileComponent';
          fileComponent: FileComponentProps;
      }
);

export const floatGroq = groq`{
  type,
  'leftOrRight': side,
  type == 'imageWithMetadata' => { imageWithMetadata ${imageWithMetadataGroq} },
  type == 'fileComponent' => { fileComponent -> ${fileComponentGroq} }
}`;

export function Float({ value: { side, _key, ...props } }: PortableTextComponentProps<FloatProps>) {
    switch (props.type) {
        case 'imageWithMetadata':
            return <CaptionedSanityImage studioPath={[{ _key }]} image={props.imageWithMetadata} float={stegaClean(side)} />;
        case 'fileComponent':
            return <FileComponent value={props.fileComponent} float={stegaClean(side)} />;
        default:
            return null;
    }
}
