export function getWishlistsURL() {
    return '/vmpws/v2/vmp/users/current/wishlists';
}

export function getWishlistURL(wishListCode: string) {
    return `/vmpws/v2/vmp/users/current/wishlists/${wishListCode}`;
}

export function getWishlistPagedURL(wishListCode: string) {
    return `/vmpws/v2/vmp/users/current/wishlists/${wishListCode}/paged`;
}

export function getWishlistEntriesURL(wishListCode: string) {
    return `/vmpws/v3/vmp/user/wishlists/${wishListCode}/entries`;
}

export function getWishlistProductEntryURL(wishListCode: string, productCode: string) {
    return `/vmpws/v3/vmp/user/wishlists/${wishListCode}/entries/${productCode}`;
}

export function getListOfWishlistsURL() {
    return '/vmpws/v2/vmp/users/current/wishlists';
}

export function updateWishlistNameURL(wishListCode: string, name: string) {
    return `/vmpws/v2/vmp/users/current/wishlists/${wishListCode}?name=${name}`;
}

export function getProductInWishlistsURL(productCode: string) {
    return `/vmpws/v2/vmp/users/current/wishlists/product/${productCode}`;
}

export function getWishlistExportURL(code: string, type: string) {
    return `/vmpws/v2/vmp/users/current/wishlists/${code}/${type}`;
}
