import {CmsComponent} from "../../CMSComponent";

export interface CmsHighlightedTextComponent extends CmsComponent {
    content?: string,
}

export default function CMSHighlightedTextComponent({component}: { component: CmsHighlightedTextComponent }) {
    return (
        <div className="article-highlighted-text">
            <p className="content">{component.content}</p>
        </div>
    );
}