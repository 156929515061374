export interface ArticleTagsProps {
    tags?: Tag[];
}

export interface Tag {
    _id: string;
    name: string;
}

export default function ArticleTags({ tags }: ArticleTagsProps) {
    if (!tags?.length) return null;
    return (
        <aside className="tags">
            <ul>
                {tags.map(({ _id, name }) => (
                    <li key={_id}>{name}</li>
                ))}
            </ul>
        </aside>
    );
}
