import {CmsLinkComponent} from '../../CMSComponent';
import { getStyleAttributeObject } from '../../../generic/componentUtils';
import cn from 'classnames';
import {getTarget, getUrlWithFallback} from "./CMSLinkComponent";

export interface CmsIconLinkComponent extends CmsLinkComponent {
    iconClassName?: string,
}

export default function CMSIconLinkComponent({component}: { component: CmsIconLinkComponent }) {
    return (
        <a style={getStyleAttributeObject(component.styleAttributes)}
           className={cn('icon-link', component.styleClasses)} href={getUrlWithFallback(component)} target={getTarget(component)}
           title={component.linkName}>
            {component.iconClassName && <span className={cn('link__icon icon', component.iconClassName)}/>}
            <span className="link__name">{component.linkName}</span>
            {component.description && <div className="link__description">{component.description}</div>}
        </a>
    );
}