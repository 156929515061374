import { memo, useEffect } from 'react';
import Pagination from '../generic/Pagination';
import { WrapTrackingContext } from '../generic/TrackingContext';
import { PaginationType } from '../model/types';
import ProductCard, { ProductCardDetails, ProductCardProps } from '../product/ProductCard';

interface Props extends ProductCardProps {
    products: ProductCardDetails[];
    numberOfProductsWidth?: number;
    pagination: PaginationType;
    onGotoPage(page: number): void;
    scrollToTop?: boolean;
    tracking_name: string;
    tracking_id: string;
}

function ProductList({
    products,
    numberOfProductsWidth = 3,
    pagination,
    scrollToTop,
    onGotoPage,
    tracking_name,
    tracking_id,
    ...productCardProps
}: Props) {
    const offset = 1 + pagination.currentPage * pagination.pageSize;

    useEffect(() => {
        gtag('event', 'view_item_list', {
            items: products.map((p, i) => ({
                item_id: p.code,
                index: i + offset,
            })),
            item_list_name: tracking_name,
            item_list_id: tracking_id,
            list_length: products.length,
            list_sort: pagination.sort,
        });
    }, [...products.map((p) => p.code)]);

    return (
        <>
            <ul className={`product-list row-max-${numberOfProductsWidth}`}>
                {products.map((product, index) => (
                    <WrapTrackingContext
                        key={product.code}
                        set={{
                            item_id: product.code,
                            index: index + offset,
                            item_list_name: tracking_name,
                            item_list_id: tracking_id,
                        }}
                    >
                        <ProductCard key={product.code} product={product} {...productCardProps} />
                    </WrapTrackingContext>
                ))}
            </ul>
            <Pagination {...pagination} onGotoPage={onGotoPage} scrollToTop={scrollToTop} />
        </>
    );
}

export default memo(ProductList);
