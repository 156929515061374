import ArticleHeader, { ARTICLE_PLACEHOLDER } from '../cms/ArticleHeader';
import CMSSlot from '../cms/CMSSlot';
import { CmsPage, useCmsContent } from '../cms/PageData';
import ErrorBox from '../generic/ErrorBox';
import StructuredArticleData from './StructuredArticleData';

export interface Props {
    pageUid: string;
    initialContent: CmsPage;
}

export default function SubCategoryPage({ pageUid, initialContent }: Props) {
    const { content, error } = useCmsContent(pageUid, initialContent);

    if (error) return <ErrorBox errors={error} />;

    return (
        <>
            <div className="section section-1">
                <ArticleHeader article={content ?? ARTICLE_PLACEHOLDER} />
            </div>
            <div className="section section-2">
                <div className="section__inner-container article-page__content">
                    <CMSSlot content={content} pagePosition="ArticleContent" />
                </div>
            </div>
            <div className="section section-3 related-articles">
                <div className="section__inner-container">
                    <CMSSlot content={content} pagePosition="ArticleNavigation" />
                </div>
            </div>
            <StructuredArticleData article={content ?? ARTICLE_PLACEHOLDER} />
        </>
    );
}
