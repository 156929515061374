import { useState } from 'react';
import * as api from '../api';
import { cmsAPI } from '../config';
import { ErrorBoxType } from '../generic/ErrorBox';
import { useOnMount } from '../utils/useComponentDidMount';
import { CmsComponent } from './CMSComponent';

export interface SmarteditProperties {
    catalogVersionUuid: string;
    componentType: string;
    componentId: string;
    classes: string;
    componentUuid: string;
}

export interface CmsPage {
    template: 'ArticlePage1Template' | 'ArticleCategoryFrontPageTemplate' | 'ArticleSubCategoryPageTemplate';
    contentSlots?: {
        contentSlots: {
            position?: string;
            slotId?: string;
            components?: {
                components: CmsComponent[];
            };
            properties?: {
                smartedit?: SmarteditProperties;
            };
        }[];
    };
    properties?: {
        smartedit: {
            classes: string;
        };
    };
}

function getPageData(pageUid: string, pageType: string = 'ContentPage', code: string = pageUid) {
    const params: Record<string, string> = {
        pageId: pageUid,
        pageType,
        code,
        fields: 'DEFAULT',
    };

    const cmsTicketId = new URL(window.location.href).searchParams.get('cmsTicketId');
    if (cmsTicketId) {
        params['cmsTicketId'] = cmsTicketId;
    }

    return api.get<CmsPage>(cmsAPI.pagesURL, params);
}

export function useCmsContent(pageUid: string, initialContent: CmsPage | undefined, pageType: string = 'ContentPage', code: string = pageUid) {
    const [content, setContent] = useState<CmsPage>(initialContent!);
    const [error, setError] = useState<ErrorBoxType | null>(null);

    useOnMount(() => {
        if (!initialContent) getPageData(pageUid, pageType, code).then(setContent, setError);
    });

    return { content, error };
}
