import { SanityDocument } from '@sanity/client';
import { useContext } from 'react';
import { getFormattedDate } from '../cms/CMSComponent';
import { DataAttributeContext } from './DataAttributeContext';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemimage';
import { ImageWithMetadata, imageWithMetadataGroq } from './SanityImage';
import groq from './groq';

export const newsArticleGroq = groq`{
    _id,
    _type,
    title,
    ingress,
    firstPublished,
    bannerImage ${imageWithMetadataGroq}
}`;

export interface NewsArticleProps extends SanityDocument {
    _type: 'newsArticle';
    title: string;
    firstPublished: string;
    bannerImage: ImageWithMetadata;
    path: string;
}

export default function NewsArticleItem({ bannerImage, path, firstPublished, title }: NewsArticleProps) {
    const dataAttribute = useContext(DataAttributeContext);

    return (
        <li className={style.item}>
            <a href={path}>
                <GridItemImage image={bannerImage} />
                <div className={style.info}>
                    <time dateTime={firstPublished} data-sanity={dataAttribute?.('firstPublished')}>
                        {getFormattedDate(firstPublished)}
                    </time>
                    <h2>{title}</h2>
                </div>
            </a>
        </li>
    );
}
