import cn from 'classnames';
import { trackLinkClick } from '../../../utils/trackLinkClick';
import { CmsComponent } from '../../CMSComponent';
import CMSServiceItemComponent, { CmsServiceItemComponent } from './CMSServiceItemComponent';

export interface CmsServicesComponent extends CmsComponent {
    components?: CmsServiceItemComponent[];
}

export default function CMSServicesComponent({ component }: { component: CmsServicesComponent }) {
    if (!component.components) return null;
    return (
        <div
            className={cn('services-component__items', `items-${component.components.length}`)}
            onClick={trackLinkClick}
            data-module="services_component"
        >
            {component.components.map((item, index) => (
                <div key={item.uid} className={cn('services-component', `service-component-${index}`)}>
                    <CMSServiceItemComponent component={item} />
                </div>
            ))}
        </div>
    );
}
