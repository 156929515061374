import { useContext, useEffect } from 'react';
import Icon from '../generic/Icon';
import { TrackingContext } from '../generic/TrackingContext';
import useBooleanState from '../utils/useBooleanState';

export default function ProductShareTool({ url, title }: { url: string; title: string | undefined }) {
    const [show, setShow] = useBooleanState();

    const { item_id, item_list_id, item_list_name } = useContext(TrackingContext);

    useEffect(() => {
        if (typeof navigator.share !== 'undefined') {
            setShow.toTrue();
        }
    }, []);

    if (!show) return null;

    const share = () => {
        navigator.share({
            url,
            title,
            text: `Hei,\nJeg tror du vil like denne: ${title}.\n${url}`,
        });

        gtag('event', 'share', {
            item_id,
            item_list_id,
            item_list_name,
        });
    };

    return (
        <button className="btn-icon" onClick={share}>
            <Icon className="icon-share" />
        </button>
    );
}
