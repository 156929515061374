import * as api from '.';
import { contentSearchURL, productGuideURL, searchURL, tagSearchURL } from '../config/SearchAPIConfig';
import { PaginationType } from '../model/types';
import { ProductCardDetails } from '../product/ProductCard';
import { Article } from '../search/ArticleCard';
import { SortValue } from '../sortSelector/SortSelector';
import { catchUnauthorizedAndRetry } from '../utils/catchAndRetry';

export interface SearchResponse {
    contentSearchResult: ContentSearchResponse;
    productSearchResult?: ProductSearchResponse;
    searchType: 'product' | 'content';
    previousKeyword?: string;
}

export interface QueryValue {
    query: {
        value: string;
    };
}

export interface ProductSearchResponse {
    pagination: PaginationType;
    broadcastMessage: string;
    searchPageInfo: string;
    products: ProductCardDetails[];
    facets: FacetResponse[];
    freeTextSearch: string;
    currentQuery: QueryValue;
    spellingSuggestion: { query: string; suggestion: string };
    sorts: SortValue[];
    keywordRedirectUrl?: string;
}

export interface ContentSearchResponse {
    sorts: SortValue[];
    pagination: PaginationType;
    results: Article[];
    currentQuery: QueryValue;
    freeTextSearch: string;
    facets: FacetResponse[];
}

export interface FacetResponse {
    code: string;
    multiSelect: boolean;
    name: string;
    priority: number;
    category: boolean;
    visible: boolean;
    values: FacetValueResponse[];
    topValues?: FacetValueResponse[];
}

export interface FacetValueResponse {
    code: string;
    count: number;
    name: string;
    selected: boolean;
    query: QueryValue;
}

export const search = catchUnauthorizedAndRetry(async (queryString: string) => await api.get<SearchResponse>(searchURL(queryString)));

export const productGuideSearch = catchUnauthorizedAndRetry(
    async (query = '', queryParams?: object) => await api.get<ProductSearchResponse>(productGuideURL(new URLSearchParams({ query, ...queryParams }))),
);

export const tagSearch = catchUnauthorizedAndRetry(async (tag: string, query: string) => await api.get<SearchResponse>(tagSearchURL(tag, query)));

export const categorySearch = catchUnauthorizedAndRetry(
    async (categoryCode: string, query: string) => await api.get<SearchResponse>(contentSearchURL(categoryCode, query)),
);
