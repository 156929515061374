import BackLink from './BackLink';

export interface Props {
    heading: string;
    subHeading?: string;
    backHref?: string;
}

export default function PageHeading({ heading, subHeading, backHref }: Props) {
    return (
        <div className="page-heading">
            <div className="page-heading-top-row">
                {backHref && <BackLink href={backHref}/>}
                <h1>{heading}</h1>
                {backHref && <div/>}
            </div>
            {subHeading && <span className="page-sub-header">{subHeading}</span>}
        </div>
    );
}
