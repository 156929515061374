import { ProductCardDetails } from '../product/ProductCard';
import { ProductArea } from '../product/productdetails/ProductOriginLinks';
import { DetailsProductTab } from '../product/productdetails/ProductTabDetails';
import { Product } from './types';

export function getCategories(product: Product) {
    return [product.main_category?.name, product.main_sub_category?.name, product.main_sub_sub_category?.name].filter(Boolean).join(' - ');
}

export function getCountryAndDistrict(product: ProductArea) {
    return [product.main_country?.name, product.district?.name, product.sub_District?.name].filter(Boolean).join(', ');
}

export function getFormattedVolume(product: Product) {
    const volume = product.volume;
    if (volume?.value) {
        return volume.formattedValue;
    }
    return null;
}

export function getProductDetailsForSR(product: ProductCardDetails) {
    const volume = getFormattedVolume(product);
    const countryAndDistrict = getCountryAndDistrict(product);

    return `
        ${product.name}.
        ${product.main_category?.name}.
        ${countryAndDistrict ? `${countryAndDistrict}. ` : ''}
        ${product.price ? `Pris: ${product.price.readableValue}. ` : ''}
        ${volume ? `Volum: ${volume}. ` : ''}
        ${product.litrePrice ? `Literpris: ${product.litrePrice.readableValue}` : ''}
    `;
}

export function isGiftCategory(product: Product) {
    return product.main_category?.code.startsWith('gaveartikler');
}

export function isGiftPackaging(product: Product) {
    if (isGiftCategory(product)) {
        const mainSubCategory = product.main_sub_category?.code.split('_');
        return mainSubCategory?.includes('gaveposer') || mainSubCategory?.includes('gaveesker');
    }
    return false;
}

export function isGiftCard(product: Product): product is Product & { code: 'gavekort' | 'egavekort' } {
    return isDigitalGiftCard(product) || isPhysicalGiftCard(product);
}

export function isPhysicalGiftCard(product?: Product) {
    return product?.code === 'gavekort';
}

export function isDigitalGiftCard(product?: Product) {
    return product?.code === 'egavekort';
}

export function getPackagingString(product: DetailsProductTab) {
    const env = product.environmentalPackaging;
    return [product.packageType, env ? 'Klimasmart' : null].filter(Boolean).join(', ');
}
