import { trackLinkClick } from '../../../utils/trackLinkClick';
import ArticleModule, { CmsArticleListComponent } from './ArticleModule';

export default function ArticleModuleComponent({ component }: { component: CmsArticleListComponent }) {
    return (
        <div className="frontpage-article-module" onClick={trackLinkClick} data-module={component.name}>
            <ArticleModule component={component} />
        </div>
    );
}
