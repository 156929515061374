import cn from 'classnames';
import { ApiResponseError } from '../api';
import Icon from './Icon';

export type ErrorBoxType = string | { message: string }[] | ApiResponseError | { error: string; error_description: string };

export interface Props {
    errors?: ErrorBoxType | null;
    className?: string;
}

export default function ErrorBox({ errors, className }: Props) {
    if (errors == null) {
        return null;
    }

    return (
        <div className={cn('error-message', className)} aria-live="assertive">
            <Icon className="icon-alert-error" />
            <ErrorMessage errors={errors} />
        </div>
    );
}

function ErrorMessage({ errors }: { errors: ErrorBoxType }) {
    if (errors instanceof ApiResponseError) {
        return <ErrorMessage errors={errors.errors} />;
    } else if (Array.isArray(errors)) {
        if (errors.length > 1) {
            return (
                <ul className="message-text">
                    {errors.map((error, i) => (
                        <li key={i}>{error.message}</li>
                    ))}
                </ul>
            );
        } else {
            return <div className="message-text">{errors[0].message}</div>;
        }
    } else if (typeof errors === 'string') {
        return <div className="message-text">{errors}</div>;
    } else if (errors && typeof errors === 'object' && errors.error_description) {
        return <div className="message-text">{errors.error_description}</div>;
    } else {
        console.log('ErrorBox not set up to present error of type:', typeof errors);
        console.log(errors);
        return <div className="message-text">Det har oppstått en ukjent feil.</div>;
    }
}
