import { PropsWithChildren } from 'react';
import Spinner from './Spinner';

export interface Props {
    isSpinning?: boolean;
    className?: string;
}

export default function SpinnerContainer({ isSpinning = false, className = '', children }: PropsWithChildren<Props>) {
    return (
        <div className={`spinner-container ${className}`}>
            <Spinner isSpinning={isSpinning} className="spinner--internal" />
            {children}
        </div>
    );
}
