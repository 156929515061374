import { useEffect, useState } from 'react';
import * as api from '../api';
import { site, storeAPI } from '../config';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import Spinner from '../generic/Spinner';
import { StoreWithOpeningTimes } from '../model/types';
import useBooleanState from '../utils/useBooleanState';
import usePropState from '../utils/usePropState';
import OpenMapApplicationAction from './OpenMapApplicationAction';
import OpeningsTable from './OpeningsTable';

interface Props {
    storeId: string;
    showStoreSelectionLink?: boolean;
    showAssortment?: boolean;
}

export default function StoreDetails({ storeId, showStoreSelectionLink = true, showAssortment = true }: Props) {
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const [store, setStore] = usePropState<StoreWithOpeningTimes | null>(null);

    useEffect(() => {
        setError(null);
        setIsLoading.toTrue();
        api.getAnonymously(storeAPI.storeURL(storeId), { fields: 'FULL' })
            .then((data) => setStore(data))
            .catch(setError)
            .finally(setIsLoading.toFalse);
    }, [storeId]);

    return (
        <div className="store-details">
            {!store ? (
                <Spinner isSpinning={isLoading} />
            ) : (
                <>
                    <ErrorBox errors={error} />
                    <StoreDetailsHeader store={store} showAssortment={!!showAssortment} />
                    <OpeningsTable openingTimes={store.openingTimes} />
                    <div className="store-info__actions">{showStoreSelectionLink && <StoreSelectionLink name={store.name} />}</div>
                </>
            )}
        </div>
    );
}

export const StoreSelectionLink = ({ name }: { name: string }) => (
    <button className="btn-secondary" onClick={() => (window.location.href = site.searchStockURL(name))} type="button">
        Se vareutvalget i denne butikken
    </button>
);

export const StoreDetailsHeader = ({ store, showAssortment }: { store: StoreWithOpeningTimes; showAssortment: boolean }) => (
    <div className="store-finder-info">
        {store.address && (
            <p>
                {store.address.line1}, {store.address.postalCode} {store.address.town}
                {'  '}
                <span className="link-to-map">
                    <OpenMapApplicationAction
                        place={`Vinmonopolet ${store.displayName} ${store.address.line1}`}
                        latitude={store.geoPoint.latitude}
                        longitude={store.geoPoint.longitude}
                    />
                </span>
            </p>
        )}
        {showAssortment && store.assortment && (
            <p>
                {store.assortment}
                <a href={site.storeCategoriesUrl()}>Les mer om kategoriene</a>
            </p>
        )}
        {store.infoMessage && <InfoBox message={store.infoMessage} />}
    </div>
);
