import Icon from '../../generic/Icon';
import groq from '../groq';
import { RichText } from '../richTextComponents';
import { markDefsGroq } from '../richTextComponents/markDefsGroq';
import * as styles from './Faq.module.less';

export interface Faq {
    faqItems: FaqItem[];
}

export interface FaqItem {
    _id: string;
    question: string;
    answer: any;
}

export const faqGroq = groq`{
    _id,
    _key,
    question,
    answer[] {
        ...,
        markDefs[] ${markDefsGroq}
    }
}`;

export function Faq({ faqItems }: Faq) {
    if (!faqItems?.length) return null;
    return (
        <div className={styles.faq}>
            {faqItems.map(({ question, answer, _id }) => (
                <details className={styles.faqItem} key={_id}>
                    <summary>
                        {question}
                        <Icon className="icon-details"></Icon>
                    </summary>
                    <div className={styles.answer}>
                        <RichText value={answer} studioPath="answer" />
                    </div>
                </details>
            ))}
        </div>
    );
}
