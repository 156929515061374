import { PortableTextComponentProps } from '@portabletext/react';
import { useContext } from 'react';
import { DataAttributeContext } from '../DataAttributeContext';
import groq from '../groq';
import SanityImage, { imageWithMetadataGroq } from '../SanityImage';

export const linkCardGroq = groq`
{
    reference -> {
        _id,
        _type,
        title,
        parent,
        slug,
        bannerImage ${imageWithMetadataGroq},
        path,
    },
    linkText,
    kicker,
    showImage
}
`;

const LinkCardImageSizes = [250, 250 * 2, 250 * 3];

export function LinkCard({ value }: PortableTextComponentProps<any>) {
    const attr = useContext(DataAttributeContext);
    return (
        <a className="link-card" href={value?.reference.path} data-sanity={attr?.([{ _key: value._key }])}>
            <h2>
                <span>{value.kicker}</span>
                {value.linkText || value.reference.title}
            </h2>
            {value.showImage && <SanityImage image={value.reference.bannerImage} sizes="250px" widths={LinkCardImageSizes} aspectRatio={16 / 9} />}
        </a>
    );
}
