import { useToggle } from 'usehooks-ts';
import ExpandButton from '../Expandable/ExpandButton';
import StoreDetails from '../storelocator/StoreDetails';
import { StoreWithDistance } from '../storelocator/StoreInfo';

export default function StoreStockInfo({ store, stockInfo }: { store: StoreWithDistance; stockInfo: { stockLevel: number } }) {
    const [expanded, toggleExpanded] = useToggle(false);
    return (
        <li className="store-info">
            <button onClick={toggleExpanded} id={'tab-' + store.name} role="tab" aria-expanded={expanded} className="btn--white">
                <ExpandButton expanded={expanded} />
                <span className="store-info__name">{store.displayName}</span>
                <span className="store-info__distance">{store.formattedDistance}</span>
                <div className="store-info__stocklevel">{stockInfo.stockLevel} stk.</div>
            </button>
            <div role="tabpanel" aria-labelledby={'tab-' + store.name}>
                {expanded && <StoreDetails storeId={store.name} showStoreSelectionLink={false} showAssortment={false} />}
            </div>
        </li>
    );
}
