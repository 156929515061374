interface Props {
    searchWithQuery(query: string): void;
    spellingSuggestion?: {query: string, suggestion: string};
}

export default function NoResults({searchWithQuery, spellingSuggestion}: Props){
    return (
        <div className="search-page__no-results">
            <h2>Beklager, ditt søk ga ingen resultater</h2>
            <p>
                Kanskje har du stavet produktnavnet feil. Det er ikke så rart, vi har titusenvis av produkter fra hele verden.{' '}
                <a href='/vinbegreper'>Denne artikkelen</a> viser hvordan vanlige ord i vinens verden staves.
                Prøv å skrive de første bokstavene i produktnavnet, da får du opp noen forslag i søkeruta. Et generelt tips er å søke på det mest
                unike ved produktets navn.
            </p>
            {!!spellingSuggestion && (
                <div className="search-page__spelling-suggestions">
                    Mente du{' '}
                    <a
                        onClick={() => {
                            searchWithQuery(spellingSuggestion.query);
                        }}
                    >
                        {spellingSuggestion.suggestion}
                    </a>
                    ?
                </div>
            )}
        </div>
    );
}