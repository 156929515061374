import CMSComponent, { SmartEditDiv } from './CMSComponent';
import { CmsPage } from './PageData';
import { getArray } from './index';

export interface Props {
    content?: CmsPage;
    pagePosition: string;
}

export default function CMSSlot({ content, pagePosition }: Props) {
    const slot = content?.contentSlots?.contentSlots?.find((element) => pagePosition === element?.position);
    const components = getArray(slot?.components?.components);

    if (!components) return null;

    return (
        <SmartEditDiv smartedit={slot?.properties?.smartedit}>
            {components.map((component) => (
                <CMSComponent key={component.uid} component={component} includeSmarteditProperties />
            ))}
        </SmartEditDiv>
    );
}
