import type { SanityDocument } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ComponentType, lazy } from 'react';
import { ImageUrlBuilderContext } from './ImageUrlBuilderContext';
import { SanityProps } from './getSanityPageProps';
import { Breadcrumb } from './pageTree';

const SanityLiveMode = lazy(() => import('../../js/sanity/SanityLiveMode'));

export type SanityPageComponent<T extends SanityDocument> = ComponentType<{ page: T; url: URL; breadcrumbs: Breadcrumb[] }>;

export default function SanityPage<T extends SanityDocument>(Component: SanityPageComponent<T>) {
    return (props: SanityProps<T>) => (
        <ImageUrlBuilderContext.Provider value={imageUrlBuilder(props.projectDetails)}>
            {props.draftMode ? (
                <SanityLiveMode {...props} component={Component as any} />
            ) : (
                <Component page={props.initial.data} url={new URL(props.url)} breadcrumbs={props.breadcrumbs} />
            )}
        </ImageUrlBuilderContext.Provider>
    );
}
