import { ChangeEventHandler } from 'react';

export interface Props {
    id: string;
    name?: string;
    label: string;
    isChecked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function Checkbox({ id, name, label, isChecked, onChange }: Props) {
    return (
        <div className="checkbox-wrapper">
            <input type="checkbox" id={id} name={name} checked={isChecked} onChange={onChange} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
}
