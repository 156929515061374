import { UserData } from '../api/product';
import { ProductCardDetails } from '../product/ProductCard';
import ProductFavouriteTool from './ProductFavouriteTool';
import ProductListTool from './ProductListTool';
import ProductShareTool from './ProductShareTool';

interface Props {
    product: ProductCardDetails;
    productUserData?: { favourite: boolean; inUserDefinedList: boolean };
    onUserDataChange(userData: UserData): void;
}

export default function ProductTools({ product, productUserData, onUserDataChange }: Props) {
    return (
        <div className="product-tools no-print">
            <ProductFavouriteTool productCode={product.code} isFavourite={productUserData?.favourite} onUserDataChange={onUserDataChange} />
            <ProductListTool product={product} inUserDefinedList={productUserData?.inUserDefinedList} onUserDataChange={onUserDataChange} />
            <ProductShareTool url={product.url} title={product.name} />
        </div>
    );
}
