import {Image} from '../../CMSComponent';
import {getStyleAttributeObject} from '../../../generic/componentUtils';
import cn from 'classnames';
import {CmsIconLinkComponent} from "./CMSIconLinkComponent";
import {getTarget, getUrlWithFallback} from "./CMSLinkComponent";

export interface CmsServiceItemComponent extends CmsIconLinkComponent {
    backgroundImage?: Image,
}

export default function CMSServiceItemComponent({component}: { component: CmsServiceItemComponent }) {
    return (
        <div style={getStyleAttributeObject(component.styleAttributes)}
             className={cn('service-item-component', {'background': component.backgroundImage?.url}, component.styleClasses)}>
            {component.backgroundImage?.url &&
                <div className="service-item-component__background">
                    <img src={component.backgroundImage.url} alt={component.backgroundImage.altText} loading="lazy" />
                </div>
            }
            <div className="service-item-component__top">
                <a className="service-item__link" href={getUrlWithFallback(component)} target={getTarget(component)} title={component.linkName}>
                    {component.iconClassName && <div className="link__icon--wrapper">
                        <div className={cn('link__icon icon', component.iconClassName)}/>
                    </div>}
                    <div className="service-item__title">{component.linkName}</div>
                    {component.description && <div className="link__description">{component.description}</div>}
                </a>
            </div>
        </div>
    );
}