const baseURL = '/vmpws/v2/vmp/stores';

export function storesURL() {
    return baseURL;
}

export function storeURL(storeId: string) {
    return `${baseURL}/${storeId}`;
}

export function autocompleteURL(query: string) {
    return `${baseURL}/autocomplete?query=${query}`;
}
