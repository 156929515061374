import {CmsLinkComponent} from '../../CMSComponent';
import { getStyleAttributeObject } from '../../../generic/componentUtils';
import cn from 'classnames';
import {CmsIconLinkComponent} from "./CMSIconLinkComponent";

export function getTarget(component: CmsLinkComponent) {
    return component.target === 'true' ? '_blank' : '_self';
}

export function getUrlWithFallback(component: CmsIconLinkComponent) {
    return component.url ? component.url : "/";
}

export default function CMSLinkComponent({component}: { component: CmsLinkComponent }) {
    return (
        <>
            {component.url ? (
                <a style={getStyleAttributeObject(component.styleAttributes)}
                   className={component.styleClasses} href={component.url} target={getTarget(component)} title={component.linkName}>
                    {component.linkName}
                </a>
            ) : (
                <span style={getStyleAttributeObject(component.styleAttributes)}
                      className={cn('empty-nav-item', component.styleClasses)}
                >{component.linkName}</span>
            )}
            {component.description && <div className="link__description">{component.description}</div>}
        </>
    );
}
