import { MouseEventHandler, memo, useCallback } from 'react';
import Icon from './Icon';

export interface Props {
    onGotoPage(page: number): void;
    scrollToTop?: boolean;
    totalPages: number;
    currentPage: number;
}

function Pagination({ onGotoPage, totalPages, currentPage, scrollToTop = true }: Props) {
    if (totalPages < 2) return null;

    const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            if (scrollToTop) window.scrollTo(0, 0);
            onGotoPage(currentPage + parseInt(e.currentTarget.value));
        },
        [scrollToTop, currentPage, onGotoPage],
    );

    return (
        <div className="pagination">
            {currentPage === 0 ? (
                <span className="pagination-button" />
            ) : (
                <button type="button" aria-label="Gå til forrige side" className="pagination-button" onClick={onClick} value={-1}>
                    <Icon className="icon-chevron-left" />
                </button>
            )}
            <span className="pagination-text">{`Side ${currentPage + 1} av ${totalPages}`}</span>
            {currentPage + 1 === totalPages ? (
                <span className="pagination-button" />
            ) : (
                <button type="button" aria-label="Gå til neste side" className="pagination-button" onClick={onClick} value={1}>
                    <Icon className="icon-chevron-right" />
                </button>
            )}
        </div>
    );
}

export default memo(Pagination);
