import CMSSlot from '../cms/CMSSlot';
import { CmsPage, useCmsContent } from '../cms/PageData';
import ErrorBox from '../generic/ErrorBox';

export interface Props {
    initialContent?: CmsPage;
}

export default function HomePage({ initialContent }: Props) {
    const { content, error } = useCmsContent('homepage', initialContent);

    if (error) return <ErrorBox errors={error} />;

    return (
        <>
            <div className="section section-3">
                <div className="section__inner-container full-width">
                    <CMSSlot content={content} pagePosition="Section3" />
                </div>
            </div>
            <div className="section section-4">
                <div className="section__inner-container">
                    <CMSSlot content={content} pagePosition="Section4" />
                </div>
            </div>
            <div className="section section-5">
                <div className="section__inner-container">
                    <CMSSlot content={content} pagePosition="Section5" />
                </div>
            </div>
        </>
    );
}
