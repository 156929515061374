import * as api from '.';
import { wishlistAPI } from '../config';
import { UserData } from './product';

export interface ProductInList {
    readonly inList: boolean;
    readonly name: string;
    readonly code: string;
}

export async function getProductInWishlists(productCode: string) {
    const { lists } = await api.get<{ lists: ProductInList[] }>(wishlistAPI.getProductInWishlistsURL(productCode));
    return lists;
}

export async function addToWishlist(listId: string, productCode: string) {
    return await api.post<UserData>(wishlistAPI.getWishlistEntriesURL(listId), { productCode });
}

export async function removeFromWishlist(listId: string, productCode: string) {
    return await api.delete<UserData>(wishlistAPI.getWishlistProductEntryURL(listId, productCode));
}
