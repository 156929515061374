import { trackLinkClick } from '../../../utils/trackLinkClick';

export default function UspBar() {
    return (
        <section className="usp" onClick={trackLinkClick} data-module="usp">
            <div className="usp-bar">
                <div className="usp-item">
                    <a href="/samfunnsoppdrag" className="usp-link">
                        Vi selger med ansvar
                    </a>
                </div>
                <div className="usp-item">
                    <a href="/klima-og-miljo" className="usp-link">
                        Klima og miljø
                    </a>
                </div>
                <div className="usp-item">
                    <a href="/anstendig-arbeid" className="usp-link">
                        Anstendig arbeid
                    </a>
                </div>
            </div>
        </section>
    );
}
