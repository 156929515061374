import { useState } from 'react';
import { DetectResponse, HybrisPage, HybrisPageWithMetadata } from '.';
import * as api from '../api';
import { getArray } from '../cms';
import { cmsAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import { useOnMount } from '../utils/useComponentDidMount';

export default function ForbidenCmsPage() {
    const [data, setData] = useState<HybrisPageWithMetadata | null>(null);
    const [error, setError] = useState<string | null>(null);

    useOnMount(() => {
        api.get<DetectResponse>(cmsAPI.cmsDetectURL(location.pathname, location.search)).then((json) => {
            if (json.status === 403) {
                setError('Du har ikke tilgang til denne siden');
            } else if (json.status === 301) {
                location.replace(json.redirection);
            } else {
                setData({
                    pageUid: json.uid,
                    masterTemplate: json.masterTemplate,
                    pageTitle: json.pageTitle,
                    metaTags: getArray(json.metaTags?.elements),
                    metaRobots: json.metaRobots,
                    tag: json.tag,
                    categoryCode: json.code,
                    backofficeDomain: null,
                } as HybrisPageWithMetadata);
            }
        });
    });

    if (error) return <ErrorBox errors={error} />;

    if (!data) return null;

    return <HybrisPage {...data} />;
}
