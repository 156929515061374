import React, { useEffect } from 'react';
import useBooleanState from '../utils/useBooleanState';
import ExpandButton from './ExpandButton';

export interface Props {
    title: string;
    iconPlacement?: 'left' | 'right';
    colorTheme?: 'light' | 'dark';
    className?: string;
    isExpanded?: boolean;
    onClickHeading?(expanded: boolean): void;
}

export default function Expandable({
    children,
    title,
    colorTheme = 'light', //todo: remove this unused prop now
    className = '',
    iconPlacement = 'right',
    isExpanded = false,
    onClickHeading,
}: React.PropsWithChildren<Props>) {
    const [expanded, setExpanded] = useBooleanState(isExpanded);

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    const toggleExpand = () => {
        setExpanded.toggle();
        onClickHeading?.(!expanded);
    };

    return (
        <div className={`expandable expandable__theme--${colorTheme} ${expanded ? 'expanded' : 'collapsed'} ${className}`}>
            <button aria-expanded={expanded} className={`expandable__header expandable__icon--${iconPlacement}`} onClick={toggleExpand}>
                <span className={'expandable__title'} dangerouslySetInnerHTML={{ __html: title }} />
                <ExpandButton expanded={expanded} background={colorTheme} />
            </button>
            {expanded && children}
        </div>
    );
}
