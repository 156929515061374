import { MouseEvent } from "react";

export interface Props {
    href: string;
}

export default function BackLink({ href }: Props) {
    const maybeGoBack = (e: MouseEvent) => {
        try {
            const a = new URL(document.referrer);
            const b = new URL(href, document.location.href);

            if (history.state === null && a.pathname === b.pathname && a.search === b.search) {
                history.back();
                e.preventDefault();
            }
        } catch (e) {
            console.log(e);
            // do the default
        }
    };

    return (
        <div className="back-link__container">
            <a className="page-back-link" href={href} onClick={maybeGoBack}>
                Tilbake
            </a>
        </div>
    );
}
