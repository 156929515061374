import { useContext } from 'react';
import { DataAttributeContext } from './DataAttributeContext';
import { TaggedSanityDocument } from './Grid';
import * as style from './Grid.module.less';
import GridItemImage from './GridItemimage';
import { ImageWithMetadata, imageWithMetadataGroq } from './SanityImage';
import groq from './groq';

export const taggedGriditemGroq = groq`{
    _id,
    _type,
    slug,
    title,
    ingress,
    bannerImage ${imageWithMetadataGroq},
    tags[] -> {
        _id,
        name,
        slug,
        mainTagCategory -> {
            _id,
            name,
            slug
        }
    }
}`;

export interface TaggedGridItemProps extends TaggedSanityDocument<'recipeArticle' | 'drinkRecommendationArticle' | 'blogArticle' | 'vintageArticle'> {
    path: string;
    title: string;
    ingress: string;
    bannerImage: ImageWithMetadata;
    showIngress?: boolean;
}

export default function TaggedGridItem({ bannerImage, path, title, ingress, tags, showIngress = false }: TaggedGridItemProps) {
    const dataAttr = useContext(DataAttributeContext);
    return (
        <li className={style.item}>
            <a href={path}>
                <GridItemImage image={bannerImage} />
                <div className={style.info}>
                    <h2>{title}</h2>
                    {showIngress && <p>{ingress}</p>}
                    <ul className={style.tags} data-sanity={dataAttr?.('tags')}>
                        {tags?.filter(Boolean).map((t) => <li key={t.name}>{t.name}</li>)}
                    </ul>
                </div>
            </a>
        </li>
    );
}
