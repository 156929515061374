import { MouseEvent, useCallback, useContext, useState } from 'react';
import { TrackingContext } from '../generic/TrackingContext';
import { getCountryAndDistrict, getFormattedVolume, getProductDetailsForSR, isGiftCard } from '../model/Product';
import { Product } from '../model/types';
import ProductTools from '../product-tools/ProductTools';
import AddToCartAction from './AddToCartAction';
import ProductAvailability, { Availability } from './ProductAvailability';
import ProductImage from './ProductImage';
import RatingAndComment from './RatingAndComment';
import { ProductArea } from './productdetails/ProductOriginLinks';
import { SustainableCorner } from './productdetails/ProductStickers';
import AltSR from '../generic/AltSR';

export interface ProductCardProps {
    showAvailability?: boolean;
    showReview?: boolean;
    onClick?(product: ProductCardDetails): void;
}

export interface Props extends ProductCardProps {
    product: ProductCardDetails;
}

export interface ProductCardDetails extends ProductArea, Product {
    price: { readableValue: string; formattedValue: string; value: number };
    litrePrice?: { readableValue: string; formattedValue: string };
    buyable: boolean;
    productAvailability: Availability;
    sustainable?: boolean;
    productUserData?: {
        favourite: boolean;
        inUserDefinedList: boolean;
        review?: {
            comment: string;
            rating: number;
        };
        statusNotificationRegistered?: boolean;
    };
    entryCreationDate: { formattedValue: string; readableValue: string; value: string };
}

export default function ProductCard({ product, onClick, showAvailability = false, showReview = false }: Props) {
    const [productUserData, setProductUserData] = useState(product.productUserData);

    const { item_id, index, item_list_id, item_list_name } = useContext(TrackingContext);

    const handleInteraction = useCallback(
        (e: MouseEvent<unknown>) => {
            if (e.target instanceof HTMLElement) {
                // ignore interactions happening inside dialogs
                if (e.target.closest('dialog')) return;
                if (!e.target.closest('button,a')) return;
                gtag('event', 'select_item', {
                    items: [{ item_id, index }],
                    item_list_id,
                    item_list_name,
                });
            }
        },
        [item_id, index, item_list_id, item_list_name],
    );

    return (
        <li key={product.code} className="product-item" onClick={handleInteraction}>
            {product.sustainable && <SustainableCorner />}
            <ProductImageAndInfo product={product} onClick={onClick} />

            <ProductTools product={product} productUserData={productUserData} onUserDataChange={setProductUserData} />

            <div className="product-item__bottom-container">
                {showAvailability && <ProductAvailability product={product} availability={product.productAvailability} />}
                {showReview && !isGiftCard(product) && (
                    <div className="product-item-review">
                        <RatingAndComment productId={product.code} review={productUserData?.review} onUserDataChange={setProductUserData} />
                    </div>
                )}
                {product.entryCreationDate && (
                    <div className="entry-creation-date">
                        <AltSR text={product.entryCreationDate.formattedValue} sr={`Lagt til i liste ${product.entryCreationDate.readableValue}`} />
                    </div>
                )}
            </div>
        </li>
    );
}

interface ProductImageAndInfoProps {
    product: ProductCardDetails;
    onClick?: (product: ProductCardDetails) => void;
    showAddToCart?: boolean;
}

export function ProductImageAndInfo({ product, onClick, showAddToCart = true }: ProductImageAndInfoProps) {
    const handleClick = () => onClick?.(product);
    const volume = getFormattedVolume(product);
    return (
        <div className="image-and-info-container">
            <a
                href={product.url}
                onClick={handleClick}
                tabIndex={0}
                className="product__image-container"
                aria-label={getProductDetailsForSR(product)}
            >
                <ProductImage product={product} />
                {product.expired && <div className="expired-img">Utgått</div>}
            </a>
            <div className="product-item__info-wrapper">
                <div className="info-container" aria-hidden={true}>
                    <div className="product__category-name">{product.main_category?.name ?? product.main_category?.code}</div>
                    <a href={product.url} className="link-text button" onClick={handleClick} tabIndex={-1}>
                        <div className="product__name">{product.name}</div>
                    </a>
                    <div className="product__code">{product.code}</div>
                    <div className="product__district">{getCountryAndDistrict(product)}</div>
                </div>
                {product.expired ? (
                    <div className="product-price-expired">Utgått</div>
                ) : (
                    <>
                        <div className="price-panel" aria-hidden={true}>
                            {!!product.price && !isGiftCard(product) && <span className="product__price">{product.price.formattedValue}</span>}
                            {!!volume && <span className="amount">{volume}</span>}
                        </div>
                    </>
                )}
                {!isGiftCard(product) && showAddToCart && (
                    <AddToCartAction code={product.code} buyable={product.buyable} maxQuantity={product.maxQuantity} />
                )}
            </div>
        </div>
    );
}
