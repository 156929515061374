import { useState } from 'react';
import ErrorBox from '../generic/ErrorBox';
import InfoBox from '../generic/InfoBox';
import StoreLocatorForm, { Coords } from '../storelocator/StoreLocatorForm';

export interface Props {
    onLocationChange(location: Coords): void;
}

export default function StoreFacet({ onLocationChange }: Props) {
    const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    const onChangedLocation = (location: Coords) => {
        setInfoMessage(undefined);
        setError(null);
        onLocationChange(location);
    };

    return (
        <div className="store-facet">
            <StoreLocatorForm
                onFail={setError}
                onLocationFail={setInfoMessage}
                onLocationChange={onChangedLocation}
                startWithLocationSearch={false}
            />
            <InfoBox message={infoMessage} />
            <ErrorBox errors={error} />
        </div>
    );
}
