import cn from 'classnames';
import { MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import Icon from '../generic/Icon';
import { FacetData, FacetGroup } from '../model/Facets';
import { Coords } from '../storelocator/StoreLocatorForm';
import { onElementBlur } from '../utils/onElementBlur';
import { FacetList, Facets } from './Facets';
import * as style from './SearchFacets.module.less';
import SelectedFacets from './SelectedFacets';

interface Props {
    facets: FacetData;
    totalResults: number;
    facetsPopover: FacetGroup | boolean;
    onFacetValueSelect(query: string): void;
    onFacetValueUnselect?(query: string): void;
    onLocationChange(location: Coords): void;
    onFacetsCloseClicked(): void;
    onResetClicked(): void;
}

export default function SearchFacets({
    facets: { groupedFacets, selectedFacets, any },
    totalResults,
    facetsPopover,
    onFacetValueSelect,
    onFacetValueUnselect = onFacetValueSelect,
    onLocationChange,
    onFacetsCloseClicked,
    onResetClicked,
}: Props) {
    const visible = !!facetsPopover;
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // when popup opens, place focus within it
        if (visible) {
            popupRef.current?.querySelector('button')?.focus();
        }
    }, [visible]);

    const onClick: MouseEventHandler = useCallback(
        (e) => {
            // trick to hide the popup when clicking on the darkend background
            if (e.clientX > popupRef.current!.clientWidth) {
                onFacetsCloseClicked();
            }
        },
        [onFacetsCloseClicked],
    );

    if (!any) return null;

    return (
        <div
            tabIndex={-1}
            id="search-refinements"
            className={cn(style.facets, { [style.visible]: visible, [style.selectedFacet]: typeof facetsPopover === 'object' })}
            ref={popupRef}
            onBlur={onElementBlur(onFacetsCloseClicked)}
            onClick={onClick}
        >
            {typeof facetsPopover === 'object' ? (
                <>
                    <FacetsTopBar title={facetsPopover.name} onFacetsCloseClicked={onFacetsCloseClicked} />
                    <div className={style.selectedFacets}>
                        <SelectedFacets
                            selectedFacets={selectedFacets.filter((f) => f.code === facetsPopover.code)}
                            onFacetValueUnselect={onFacetValueUnselect}
                        />
                    </div>
                    <FacetList facets={facetsPopover.facets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
                </>
            ) : (
                <>
                    <FacetsTopBar
                        title={selectedFacets.length > 0 ? 'Valgte filtre' : 'Ingen filtre valgt'}
                        showResetButton={selectedFacets.length > 0}
                        onResetClicked={onResetClicked}
                        onFacetsCloseClicked={onFacetsCloseClicked}
                    />

                    <div className={style.selectedFacets}>
                        <SelectedFacets selectedFacets={selectedFacets} onFacetValueUnselect={onFacetValueUnselect} />
                    </div>
                    <Facets facets={groupedFacets} onFacetValueSelect={onFacetValueSelect} onLocationChange={onLocationChange} />
                </>
            )}
            <div className={style.applyButtonWrapper}>
                <button onClick={onFacetsCloseClicked}>Vis resultat ({totalResults})</button>
            </div>
        </div>
    );
}
interface FacetsTopBarProps {
    title: string;
    showResetButton?: boolean;
    onResetClicked?(): void;
    onFacetsCloseClicked(): void;
}

function FacetsTopBar({ title, showResetButton, onResetClicked, onFacetsCloseClicked }: FacetsTopBarProps) {
    return (
        <div className={style.topBar}>
            <h2>{title}</h2>
            {showResetButton && onResetClicked && (
                <button
                    className="link"
                    onClick={() => {
                        gtag('event', 'filter_remove', { filter_type: 'alle' });
                        onResetClicked();
                    }}
                >
                    Nullstill
                </button>
            )}
            <button className={cn('button', style.closeFacetsButton)} onClick={onFacetsCloseClicked}>
                <Icon className="icon-close-button" />
            </button>
        </div>
    );
}
