import cn from 'classnames';
import { CmsComponent, CmsLinkComponent } from '../../CMSComponent';
import ArticleCard, { CmsArticleCard } from './ArticleCard';

export interface CmsArticleListComponent extends CmsComponent {
    articleListComponentCards?: {
        cards: CmsArticleCard[];
    };
    title?: string;
    content?: string;
    mainLink?: CmsLinkComponent;
}

interface Props {
    component: CmsArticleListComponent;
    unlimitedNumberOfCards?: boolean;
    title?: string;
}

export default function ArticleModule({ component, unlimitedNumberOfCards, title }: Props) {
    const cards = component.articleListComponentCards?.cards;
    if (!cards || cards.length === 0) return null;
    const cardIncludeIngress = !unlimitedNumberOfCards && cards.length === 1;
    const cardClassName = `media__count--${cards.length}`;
    const titleWithFallback = title ? title : component.title;
    return (
        <section className={cn('article-module', { 'articles-overflow': !unlimitedNumberOfCards && cards.length > 7 }, `articles-${cards.length}`)}>
            {titleWithFallback && (
                <div className="heading-separator section-heading">
                    <span className="heading-separator--line" />
                    <h2>
                        {titleWithFallback}
                        {component.mainLink && (
                            <a href={component.mainLink.url} className="article-module__link">
                                {component.mainLink.linkName}
                            </a>
                        )}
                    </h2>
                    <span className="heading-separator--line" />
                </div>
            )}
            {component.content && (
                <div className="article-page__content">
                    <div className="content" dangerouslySetInnerHTML={{ __html: component.content }} />
                </div>
            )}
            <div className={cn('article-module__items', { 'article-module__items--title': titleWithFallback })}>
                {cards.map((card) => (
                    <ArticleCard key={card.url} card={card} includeIngress={cardIncludeIngress} className={cardClassName} />
                ))}
            </div>
        </section>
    );
}
