import { JSXElementConstructor, PropsWithChildren, ReactElement, createContext, createElement, useContext } from 'react';
import { getComponent } from '../clientComponents';

const isServer = typeof window === 'undefined';

const ClientContext = createContext(false);

export function ClientRendering({ children }: PropsWithChildren) {
    return <ClientContext.Provider value={true}>{children}</ClientContext.Provider>;
}

export default function ClientReactRoot<As extends keyof JSX.IntrinsicElements = 'div'>({
    as,
    children,
    ...props
}: { as?: As; children: ReactElement<any, JSXElementConstructor<any>> } & JSX.IntrinsicElements[As]) {
    const alreadyClient = useContext(ClientContext);
    const shouldDefine = isServer && !alreadyClient;
    return createElement(
        as ?? 'div',
        {
            ...props,
            'data-react-component': shouldDefine ? assertComponentDefined(children.type.name) : undefined,
            'data-react-props': shouldDefine ? JSON.stringify(children.props) : undefined,
        },
        createElement(ClientRendering, null, children),
    );
}

function assertComponentDefined(name: string) {
    getComponent(name);
    return name;
}
