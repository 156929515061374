import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../groq';
import { SimpleRichText } from '../richTextComponents';
import { markDefsGroq } from './markDefsGroq';

export const textboxWithColorGroq = groq`
{
    _key,
    _type,
    color,
    text[] {
        ...,
        markDefs[] ${markDefsGroq}
    }
}
`;

export function TextboxWithColor({ value }: PortableTextComponentProps<any>) {
    return (
        <div className={`textbox-with-color textbox-with-color--${value.color}`}>
            <h2>{value.title}</h2>
            <SimpleRichText value={value.text} />
        </div>
    );
}
