import cn from 'classnames';
import { trackLinkClick } from '../utils/trackLinkClick';
import { Image } from './CMSComponent';
import { CmsPage } from './PageData';
import { getArray } from './index';
export interface Article extends CmsPage {
    articleBannerImage?: Image;
    illustrationImage?: string;
    title?: string;
    ingress?: string;
    photocredit?: string;
    tags?: {
        elements: NavigationalElement[];
    };
    categories?: {
        elements: NavigationalElement[];
    };
}

export interface NavigationalElement {
    name: string;
    url: string;
}
export const ARTICLE_PLACEHOLDER = {
    title: '',
    ingress: '',
};

export interface Props {
    article: Article;
}

export default function ArticleHeader({ article: { title, ingress, articleBannerImage, photocredit, illustrationImage, ...article } }: Props) {
    const categories = getArray(article.categories?.elements);
    const tags = getArray(article.tags?.elements);
    return (
        <div className="section__inner-container">
            <article
                className={cn('article', {
                    'article__has-illustration': illustrationImage === 'true',
                    'article__no-image': !articleBannerImage?.url,
                })}
            >
                <div className="article-page-heading">
                    <div className="article__back--container">
                        <div className="article__back">
                            <a className="page-back-link" href="#" onClick={() => window.history.back()}>
                                Tilbake
                            </a>
                        </div>
                    </div>
                    <div className="article__content article-page__content">
                        {!!categories.length && (
                            <div className="article-breadcrumbs">
                                <div className="article-breadcrumbs_content">
                                    {categories.map(({ url, name }) => (
                                        <a href={url ?? '#'} key={name}>
                                            {name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}

                        <h1 className="article__headline">{title}</h1>
                        {!!ingress && <div className="article__leadin" dangerouslySetInnerHTML={{ __html: ingress }} />}

                        {!!tags.length && (
                            <div className="article__tags" onClick={trackLinkClick} data-module="artikkel tagger">
                                {tags.map(({ url, name }) => (
                                    <a href={url} key={name}>
                                        {name}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {articleBannerImage?.url && (
                    <div className="article__image-container">
                        <img src={articleBannerImage.url} alt={articleBannerImage.altText} title={articleBannerImage.altText} />
                        {!!photocredit && (
                            <div className="article-page__content">
                                <div className="article__image-credits" dangerouslySetInnerHTML={{ __html: photocredit }} />
                            </div>
                        )}
                    </div>
                )}
            </article>
        </div>
    );
}
