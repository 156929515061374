import { PortableTextBlock } from '@portabletext/react';
import slugify from 'slugify';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { Brochure, BrochureProps, brochuresGroq } from '../Brochure';
import { AlphabetList } from '../components/AlphabetList';
import Breadcrumbs from '../components/Breadcrumbs';
import GrapeItem, { grapeItemGroq, GrapeItemProps } from '../GrapeItem';
import Grid from '../Grid';
import groq, { queryable } from '../groq';
import NewsArticleItem, { newsArticleGroq, NewsArticleProps } from '../NewsArticleItem';
import { RichText, richTextGroq } from '../richTextComponents';
import SanityPage, { SanityPageComponent } from '../SanityPage';
import TaggedGridItem, { taggedGriditemGroq, TaggedGridItemProps } from '../TaggedGridItem';
import { WineMagazine, wineMagazineGroq, WineMagazineProps } from '../WineMagazine';

export interface ListPage extends SanityPageDocument {
    _type: 'listPage';
    listType: string;
    list: TaggedGridItemProps[] | NewsArticleProps[] | WineMagazineProps[] | BrochureProps[] | GrapeItemProps[];
    richText: PortableTextBlock[];
}

export const listPage = queryable<ListPage>(groq`
    *[_type == "listPage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        listType,
        slug,
        title,
        subtitle,
        ingress,
        metadata,
        richText[] ${richTextGroq},
        'list': select(
            listType == 'wineMagazine' => *[_type == ^.listType]${wineMagazineGroq} | order(releaseNumber desc),
            listType == 'newsArticle' => *[_type == ^.listType]${newsArticleGroq} | order(firstPublished desc),
            listType == "brochure" => *[_type == ^.listType]${brochuresGroq} | order(title asc),
            listType == "grape" => *[_type == ^.listType]${grapeItemGroq} | order(name asc),
            listType == "vintageArticle" => *[_type == ^.listType]${taggedGriditemGroq} | order(title asc),
            *[_type == ^.listType]${taggedGriditemGroq} | order(title asc)
        )
    }
`);

export default SanityPage<ListPage>(
    flatMapSynonymsOfGrapes(({ page, url, breadcrumbs }) => (
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
            </header>
            <section>
                <RichText value={page.richText} studioPath="richText" />
            </section>
            {page.subtitle && <h2 className="lined-heading">{page.subtitle}</h2>}
            {page?.listType === 'grape' && <AlphabetList list={page.list as GrapeItemProps[]} />}
            <Grid list={page.list} component={ListItem} selectedTags={url.searchParams.getAll('tag')} />
        </article>
    )),
);

function ListItem(props: TaggedGridItemProps | NewsArticleProps | WineMagazineProps | BrochureProps | GrapeItemProps) {
    switch (props._type) {
        case 'newsArticle':
            return <NewsArticleItem {...props} />;
        case 'wineMagazine':
            return <WineMagazine {...props} />;
        case 'brochure':
            return <Brochure {...props} />;
        case 'grape':
            return <GrapeItem {...props} />;
        case 'blogArticle':
        case 'vintageArticle':
            return <TaggedGridItem {...props} showIngress />;
        default:
            return <TaggedGridItem {...props} />;
    }
}

export function listPageIsDynamic(page: ListPage) {
    switch (page.listType) {
        case 'grape':
        case 'wineMagazine':
        case 'brochure':
            return false;
        default:
            return true;
    }
}

function flatMapSynonymsOfGrapes(Component: SanityPageComponent<ListPage>): SanityPageComponent<ListPage> {
    return ({ page, ...props }) => <Component page={isGrapeListPage(page) ? flatMapSynonyms(page) : page} {...props} />;
}

/**
 * Create an entry for each synonym as a copy of the main entry. The synonym will have a separate slug
 * which will be `main-entry-slug--synonym-slug` and it will have a synonym list that contains
 * the main entry but not itself
 */
function flatMapSynonyms(page: ListPage & { list: GrapeItemProps[] }): ListPage {
    return {
        ...page,
        list: page.list
            .flatMap((mainEntry) =>
                mainEntry.synonyms
                    ? [
                          mainEntry,
                          ...mainEntry.synonyms.map(({ title = '', shortDescription }) => {
                              return {
                                  ...mainEntry,
                                  name: title,
                                  slug: mainEntry.slug && `${mainEntry.slug}--${slugify(title)}`,
                                  synonyms: [],
                                  shortDescription,
                                  description: undefined,
                                  mainEntry,
                              } satisfies GrapeItemProps;
                          }),
                      ]
                    : [mainEntry],
            )
            .toSorted((a, b) => a.name.localeCompare(b.name)),
    };
}

function isGrapeListPage(page: ListPage): page is ListPage & { list: GrapeItemProps[] } {
    return page.listType === 'grape';
}
