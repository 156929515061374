import { categorySearch } from '../api/search';
import SearchPage from './SearchPage';

export interface Props {
    pageTitle: string;
    categoryCode: string;
}

export default function ArticleCategorySearchPage({ pageTitle, categoryCode }: Props) {
    return <SearchPage searchType="content" pageTitle={pageTitle} search={(query) => categorySearch(categoryCode, query)} hideSearchControls />;
}
