import { PortableText, PortableTextBlock } from '@portabletext/react';
import type { SanityDocument } from '@sanity/client';
import SanityPage from '../SanityPage';
import Breadcrumbs from '../components/Breadcrumbs';
import groq, { queryable } from '../groq';

interface PocPage extends SanityDocument {
    _type: 'pocPage';
    slug: { current: string };
    title: string;
    subtitle: string;
    portableText: PortableTextBlock;
}

export const pocPage = queryable<PocPage>(groq`
    *[_type == "pocPage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        slug,
        title,
        subtitle,
        portableText
    }
`);

export default SanityPage<PocPage>(({ page, breadcrumbs }) => (
    <article className="sanity">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.subtitle}</p>
        </header>
        <section>
            <PortableText value={page.portableText} />
        </section>
    </article>
));
