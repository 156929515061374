import { Product } from '../model/types';

interface Props {
    product: Product;
    imageType?: string;
    format?: string;
    productLink?: boolean
}

export default function ProductImage({product, imageType='PRIMARY', format='product', productLink=false}: Props){
    if(!!product.images){
        const image = product.images.find(image => format === image.format && imageType === image.imageType) ?? product.images[0];
        if (productLink) {
            return (
                <a href={product.url} tabIndex={-1}>
                    <img className={product.expired ? 'expired' : ''} src={image?.url} alt={'Bilde av produktet ' + product.name} loading="lazy" />;
                </a>
            );
        }
        return <img className={product.expired ? 'expired' : ''} src={image?.url} alt={'Bilde av produktet ' + product.name} loading="lazy" />;
    }else{
        return <div/>;
    }   
}
