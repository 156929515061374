import cn from 'classnames';
import { SmartEditScript } from '../../pages/preview-content';
import { PageEventContext } from '../cloudflare/types';
import { getArray } from '../cms';
import { CmsPage } from '../cms/PageData';
import { cmsAPI } from '../config';
import HomePage, { Props as HomePageProps } from '../home/HomePage';
import HeaderFooterLayout, { StaticLayoutProps } from '../layout/HeaderFooterLayout';
import NotFoundPage from '../notFoundPage';
import ArticleCategorySearchPage, { Props as ArticleCategorySearchPageProps } from '../search/ArticleCategorySearchPage';
import ArticleTagSearchPage, { Props as ArticleTagSearchPageProps } from '../search/ArticleTagSearchPage';
import Authenticated from '../utils/Authenticated';
import { setCacheControl } from '../utils/responseHelpers';
import { MainSlot } from '../utils/Slot';
import ArticlePage, { Props as ArticlePageProps } from './ArticlePage';
import CategoryPage, { Props as CategoryPageProps } from './CategoryPage';
import ForbidenCmsPage from './ForbiddenCmsPage';
import SubCategoryPage, { Props as SubCategoryPageProps } from './SubCategoryPage';

type PageProps<MasterTemplate extends string, Props = object> = { masterTemplate: MasterTemplate } & Props;

type HybrisPage =
    | PageProps<'LandingPage2Template', HomePageProps>
    | PageProps<'ArticlePage1Template', ArticlePageProps>
    | PageProps<'ArticleCategoryPageTemplate', ArticleCategorySearchPageProps>
    | PageProps<'ArticleSubCategoryPageTemplate', SubCategoryPageProps>
    | PageProps<'ArticleTagsPageTemplate', ArticleTagSearchPageProps>
    | PageProps<'ArticleCategoryFrontPageTemplate', CategoryPageProps>
    | PageProps<'ContentPage1Template'>;

interface MetaTag {
    property?: string;
    name?: string;
    content: string;
}

export type HybrisPageWithMetadata = HybrisPage & {
    pageUid: string;
    pageTitle: string;
    metaTags: MetaTag[];
    metaRobots?: string;
    backofficeDomain: string | null;
};

interface ForbiddenHybrisPage {
    masterTemplate: '403';
    pageUid?: undefined;
    pageTitle?: undefined;
    metaTags?: undefined;
    metaRobots?: undefined;
}

export type HybrisPageProps = (HybrisPageWithMetadata | ForbiddenHybrisPage) & { _type?: never };

export type DetectResponse =
    | { status: 403 }
    | { status: 301; redirection: string }
    | {
          status?: undefined;
          uid: string;
          masterTemplate: string;
          pageTitle: string;
          metaTags?: { elements: MetaTag | MetaTag[] };
          metaRobots?: string;
          tag?: string;
          code?: string;
      };

export async function hybrisContent(url: URL, { env, renderToResponse, next }: PageEventContext<'/[[slug]]', HybrisPageProps>) {
    url.searchParams.delete('preview');

    const response = await env.BACKEND.fetch(cmsAPI.cmsDetectURL(url.pathname, url.search));

    if (response.status === 404) return next();

    if (response.status !== 200)
        throw new Error(
            `Request to ${cmsAPI.cmsDetectURL(url.pathname, url.search)} with referer ${url.pathname}${url.search} responded with ${response.status}`,
        );

    const info = await response.json<DetectResponse>();

    switch (info.status) {
        case 301:
            return Response.redirect(new URL(info.redirection, url), 301);
        case 403: {
            return renderToResponse({ masterTemplate: '403' }).then(setCacheControl(`private, max-age=${5 * 60}`));
        }
        default: {
            const pageUid = info.uid;
            const cmsTicketId = url.searchParams.get('cmsTicketId');
            const contentUrl = `${cmsAPI.pagesURL}?${new URLSearchParams({
                pageUid,
                pageType: 'ContentPage',
                code: pageUid,
                fields: 'DEFAULT',
                ...(cmsTicketId && { cmsTicketId }),
            })}`;

            const initialContent = await env.BACKEND.fetch(contentUrl)
                .then((r) => r.json<CmsPage>())
                .catch(() => undefined);

            return await renderToResponse({
                pageUid,
                masterTemplate: info.masterTemplate as any,
                pageTitle: info.pageTitle,
                metaTags: getArray(info.metaTags?.elements),
                metaRobots: info.metaRobots,
                tag: info.tag,
                categoryCode: info.code,
                initialContent,
                backofficeDomain: cmsTicketId && env.BACKOFFICE_DOMAIN,
            }).then(setCacheControl(cmsTicketId ? 'no-store' : `public, s-maxage=${5 * 60}, stale-while-revalidate=30`));
        }
    }
}

export function HybrisPage(props: HybrisPageProps) {
    switch (props.masterTemplate) {
        case 'LandingPage2Template':
            return <HomePage {...props} />;

        case 'ArticlePage1Template':
            return (
                <div className="article-page">
                    <ArticlePage {...props} />
                </div>
            );

        case 'ArticleTagsPageTemplate':
            return (
                <div className="search-page search-page--tag">
                    <ArticleTagSearchPage {...props} />
                </div>
            );

        case 'ArticleCategoryPageTemplate':
            return (
                <div className="search-page search-category-page">
                    <ArticleCategorySearchPage {...props} />
                </div>
            );

        case 'ArticleSubCategoryPageTemplate':
            return (
                <div className="article-page">
                    <SubCategoryPage {...props} />
                </div>
            );

        case 'ArticleCategoryFrontPageTemplate':
            return (
                <div className="category-home-page">
                    <CategoryPage {...props} />
                </div>
            );

        case '403':
            return (
                <Authenticated>
                    <ForbidenCmsPage />
                </Authenticated>
            );

        case 'ContentPage1Template':
        default:
            return <NotFoundPage />;
    }
}

export function HybrisStaticLayout({ assets, props }: StaticLayoutProps<HybrisPageProps>) {
    if (props.masterTemplate === '403') {
        return <HeaderFooterLayout assets={assets} />;
    }

    const { pageTitle, metaTags, metaRobots, masterTemplate, ...rest } = props;

    return (
        <HeaderFooterLayout
            assets={assets}
            title={pageTitle}
            robots={metaRobots}
            className={cn(bodyClassNames[masterTemplate], 'initialContent' in rest && rest.initialContent?.properties?.smartedit.classes)}
            page_title={pageTitles[masterTemplate]}
            meta={metaTags}
        >
            <SmartEditScript backofficeDomain={rest.backofficeDomain} />
            <MainSlot />
        </HeaderFooterLayout>
    );
}

const bodyClassNames: { [P in HybrisPageWithMetadata['masterTemplate']]?: string | undefined } = {
    LandingPage2Template: 'page-homepage',
    ArticleCategoryPageTemplate: 'page-articleCategory',
};

const pageTitles: { [P in HybrisPageWithMetadata['masterTemplate']]?: string | undefined } = {
    LandingPage2Template: 'Startside',
};
