import cn from 'classnames';
import { getCategories, getCountryAndDistrict } from '../model/Product';
import { ProductCardDetails } from './ProductCard';
import ProductImage from './ProductImage';

interface Props {
    product: ProductCardDetails;
    alignment?: 'left' | 'center';
    className?: string;
}

export default function ProductMiniCard({ product, alignment = 'left', className }: Props) {
    return (
        <div className={cn(`product-mini-card product-mini-card__${alignment}`, className)}>
            <ProductImage product={product} />
            <div>
                <div className="product__category-name">{getCategories(product)}</div>
                <div className="product__name">{product.name}</div>
                <div className="product__district">{getCountryAndDistrict(product)}</div>
            </div>
        </div>
    );
}
