import Icon from "../generic/Icon";

const getDevice = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)?.[0];
};

const getUrl = (latitude: number, longitude: number, place: string) => {
    let protocol = `http://maps.google.com/maps/search/${place}/@${latitude},${longitude},15z`;
    switch (getDevice()) {
        case 'iphone':
        case 'ipad':
        case 'ipod':
            protocol = `http://maps.apple.com/?q=${place}&sll=${latitude},${longitude}&z=15&t=s`;
            break;
        case 'android':
            protocol = `geo:${latitude},${longitude}?q=${place}`;
            break;
    }
    return protocol;
};

export default function OpenMapApplicationAction({ latitude, longitude, place }: {latitude:number, longitude: number, place: string}){
    return <a href={getUrl(latitude, longitude, place)} target={getDevice() ? '_self' : '_blank'} className="open-map-application-action" rel="noreferrer">
        Vis kart
        <Icon className="icon-external-link" renderWithSpan={true}/>
    </a>
};
