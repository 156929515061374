import ArticleHeader, { ARTICLE_PLACEHOLDER } from '../cms/ArticleHeader';
import CMSSlot from '../cms/CMSSlot';
import { CmsPage, useCmsContent } from '../cms/PageData';
import ErrorBox from '../generic/ErrorBox';
import StructuredArticleData from './StructuredArticleData';

export interface Props {
    pageUid: string;
    initialContent: CmsPage;
}

export default function CategoryPage({ pageUid, initialContent }: Props) {
    const { content, error } = useCmsContent(pageUid, initialContent);

    if (error) return <ErrorBox errors={error} />;

    return (
        <>
            <div className="section">
                <ArticleHeader article={content ?? ARTICLE_PLACEHOLDER} />
            </div>
            <div className="section">
                <div className="section__inner-container">
                    <CMSSlot content={content} pagePosition="ArticleCategoryContent" />
                </div>
            </div>
            <StructuredArticleData article={content ?? ARTICLE_PLACEHOLDER} />
        </>
    );
}
