import { useState } from 'react';
import * as api from '../api';
import { wishlistAPI } from '../config';
import ErrorBox from '../generic/ErrorBox';
import SubmitButton from '../generic/forms/SubmitButton';
import eventTargetValue from '../utils/eventTargetValue';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';

export interface List {
    name: string;
    code: string;
}

export default function ProductListForm({ onCreateList }: { onCreateList(list: List): void }) {
    const [name, setName] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState();

    const createList = () => {
        setError(null);
        setIsLoading.toTrue();

        api.post(wishlistAPI.getWishlistsURL(), { name: name })
            .then((data) => {
                onCreateList(data);
                setName('');
            })
            .catch(setError)
            .finally(setIsLoading.toFalse);
    };

    return (
        <div className="new-list">
            <h2>Opprett ny liste</h2>
            <ErrorBox errors={error} />
            <form onSubmit={preventDefault(createList)}>
                <div className="formGroup">
                    <input
                        required
                        type="text"
                        value={name}
                        onChange={eventTargetValue(setName)}
                        placeholder="Skriv navn på listen"
                        className="form-control"
                        disabled={isLoading}
                    />
                    <SubmitButton isLoading={isLoading} disabled={!name}>
                        OK <span className="sr-only">, opprett ny liste</span>
                    </SubmitButton>
                </div>
            </form>
        </div>
    );
}
