import { ContentSearchResponse, FacetResponse, FacetValueResponse, ProductSearchResponse, QueryValue } from '../api/search';
import productGuideFacetsJson from '../productGuide/productGuideFacetsJson.json';
import articleFacetsJson from '../search/articleFacets.json';
import productFacetsJson from '../search/productFacets.json';
import { ContentSearchResult, ProductSearchResult } from './Search';
import { Store } from './types';

export function configureProductFacets(data: ProductSearchResponse, favouriteStore: Store | undefined): ProductSearchResult {
    return {
        ...data,
        facets: configureFacets(data.facets, productFacetsJson, favouriteStore),
    };
}

export function configureArticleFacets(data: ContentSearchResponse): ContentSearchResult {
    return {
        ...data,
        facets: configureFacets(data.facets, articleFacetsJson),
    };
}

export function configureProductGuideFacets(facets: FacetResponse[], favouriteStore: Store | undefined) {
    return configureFacets(facets, productGuideFacetsJson, favouriteStore, false);
}

function getFacet(facet: FacetResponse, json: FacetSearchJson): FacetJson | undefined {
    return json.facets[facet.code] ?? json.default;
}

function configureFacets(facets: FacetResponse[], json: FacetSearchJson, favouriteStore?: Store, allowMultiSelectIfPossible = true): FacetData {
    const configuredFacets = facets.map((facet) => configureFacet(facet, getFacet(facet, json), favouriteStore)).filter(Boolean);

    const { selectedFacets, unselectedFacets } = configureSelection(configuredFacets, allowMultiSelectIfPossible);

    const groupedFacets = configureGroups(unselectedFacets, json);

    return {
        any: !!facets.length,
        selectedFacets,
        unselectedFacets,
        groupedFacets,
        facetOrder: json.facetOrder ?? [],
    };
}

// grouping facets together based on configuration
// each facet must be configured with group property, with a corresponding group configuration
function configureGroups(facets: Facet[], json: FacetSearchJson) {
    const groupedFacets = new Map<string, FacetGroup>();
    for (const facet of facets) {
        const code = facet.group ?? facet.code;

        const facetGroup = groupedFacets.get(code);
        if (facetGroup) {
            facetGroup.facets.push(facet);
        } else {
            const { name, priority, expanded } = facet.group && json.groups ? json.groups[facet.group] : facet;
            groupedFacets.set(code, {
                code,
                name,
                priority,
                expanded,
                facets: [facet],
            });
        }
    }

    return [...groupedFacets.values()].sort((a, b) => b.priority - a.priority);
}

// regrouping facets into selected and unselected facets
function configureSelection(facets: Facet[], allowMultiSelectIfPossible: boolean) {
    const selectedFacets = facets.filter((f) => f.values.some((v) => v.selected));
    const unselectedFacets = facets.filter((f) => f.values.every((v) => !v.selected));

    if (allowMultiSelectIfPossible) {
        const multiSelectableFacets = selectedFacets
            .filter((f) => f.multiSelect)
            .map((facet) => ({
                ...facet,
                values: facet.values.filter((v) => !v.selected),
            }))
            .filter((f) => f.values.length);
        unselectedFacets.push(...multiSelectableFacets);
    }

    return {
        selectedFacets,
        unselectedFacets,
        allowMultiSelectIfPossible,
    };
}

// adding/overriding properties for facets and facet values
function configureFacet(facet: FacetResponse, facetJson: FacetJson | undefined, favouriteStore: Store | undefined): Facet | undefined {
    if (!facetJson) return undefined;

    return {
        ...facet,
        ...facetJson,
        values: facet.values.map((value) => ({
            ...value,
            ...getQueryAndHref(value.query),
            iconImageClass: getFacetIcon(facetJson, value, facet, favouriteStore),
        })),
    };
}

function getFacetIcon(
    facetJson: FacetJson | undefined,
    value: FacetValueResponse,
    facet: FacetResponse,
    favouriteStore: Store | undefined,
): string | undefined {
    if (facet.code === 'availableInStores' && value.code === favouriteStore?.name) return 'icon-my-store';
    if (!facetJson?.icons) return undefined;
    return `${facet.code} ${facetJson.values?.[value.code]?.iconImageClass ?? value.code.replaceAll(' ', '-')}`;
}

function getQueryAndHref(queryValue: QueryValue): Pick<FacetValue, 'href' | 'query'> {
    const query = getQuery(queryValue);
    return {
        query,
        href: '?' + new URLSearchParams({ q: query }).toString(),
    };
}

export function getQuery({ query: { value } }: QueryValue): string {
    /**
     * This function is needed because the backend returns the query in a horrible state!
     * The first part, what you search for, is not url-encoded, while the rest of it is.
     * So if you search for t&t then query.query.value will be "t&t:relevance", where the & is not urlencoded.
     * But if you select the mainCategory øl it gives "t&t:relevance:mainCategory:%C3%B8l", so the ø is urlencoded.
     * Searching for 40% gives "40%:relevance", but selecting alkohol: 40 - 50 % gives "40%:relevance:alcohol:40+-+45+%25".
     * decodeURIComponent("relevance:alcohol:40+-+45+%25") returns "relevance:alcohol:40+-+45+%", but we want "relevance:alcohol:40 - 45 %",
     * We can fix this by replacing all + with %20, which is the proper way to encode spaces.
     */

    // Split in two, the first part is the query and the second part is the facets
    const [, query, facets] = /(.*?):(.*)/.exec(value) ?? [, value, ''];

    // Recombine after decoding the facets
    return `${query}:${decodeURIComponent(facets.replaceAll('+', '%20'))}`;
}

export interface FacetData {
    any: boolean;
    selectedFacets: Facet[];
    unselectedFacets: Facet[];
    groupedFacets: FacetGroup[];
    facetOrder: string[];
}

export interface Facet {
    code: string;
    multiSelect: boolean;
    name: string;
    priority: number;
    expanded?: boolean;
    listClass?: string;
    booleanFacet?: boolean;
    group?: string;
    values: FacetValue[];
}

export interface FacetGroup {
    code: string;
    expanded?: boolean;
    facets: Facet[];
    name: string;
    priority: number;
}

export interface FacetValue {
    code: string;
    count: number;
    name: string;
    description?: string;
    selected: boolean;
    query: string;
    href: string;
    iconImageClass?: string;
}

interface FacetSearchJson {
    default?: FacetJson;
    facets: Record<string, FacetJson>;
    groups?: Record<string, FacetGroupJson>;
    facetOrder?: string[];
}

interface FacetJson {
    icons?: boolean;
    expanded?: boolean;
    name?: string;
    listClass?: string;
    booleanFacet?: boolean;
    group?: string;
    values?: Record<string, FacetValueJson>;
}

interface FacetValueJson {
    iconImageClass?: string;
}

interface FacetGroupJson {
    name: string;
    priority: number;
    expanded?: boolean;
}
