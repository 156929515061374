import Icon from '../generic/Icon';

export interface Props {
    expanded: boolean;
    background?: 'light' | 'dark';
}

export default function ExpandButton({ expanded, background = 'light' }: Props) {
    return (
        <div className={`expand-button ${background}`}>
            <Icon className={expanded ? 'icon-decrease' : 'icon-increase'} />
        </div>
    );
}
