import { useEffect, useState } from "react";

/**
 * useState that will also update when the initial value changes.
 *
 * @param propValue value from props
 */
export default function usePropState<T>(propValue: T) {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return [value, setValue] as const;
}