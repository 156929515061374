import CMSComponent, {CmsComponent, getFormattedDate} from "../../CMSComponent";
import cn from 'classnames';
import {CmsImageComponent} from "./CMSImageComponent";

export interface CmsPodcastComponent extends CmsComponent {
    image?: CmsImageComponent,
    date?: string,
    title?: string,
    content?: string,
    link1?: string,
    link2?: string
}

export default function CMSPodcastComponent({component}: { component: CmsPodcastComponent }) {
    return (
        <div className={cn('cms-podcast', {'noImage': !component.image})}>
            <div className={cn('podcast-image', {'noImage': !component.image})}>
                {component.image && <CMSComponent component={component.image}/>}
            </div>
            <div className={cn('podcast-content', {'noImage': !component.image})}>
                {component.date &&
                    <div className="podcast-content__date">{getFormattedDate(component.date)}</div>}
                {component.title && <h2 className="podcast-content__title">{component.title}</h2>}
                {component.content && <div className="podcast-content__text" dangerouslySetInnerHTML={{__html: component.content}}/>}
                <div className="podcast-content__links">
                    {component.link1 && <a href={component.link1} title="Hør på Spotify">Hør på Spotify</a>}
                    {component.link2 && <a href={component.link2} title="Hør på iTunes">Hør på iTunes</a>}
                </div>
            </div>
        </div>
    );
}