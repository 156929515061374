import { ContentSearchResponse, ProductSearchResponse } from '../api/search';
import { ProductCardDetails } from '../product/ProductCard';
import { Article } from '../search/ArticleCard';
import { SortValue } from '../sortSelector/SortSelector';
import { setUrl } from '../utils/url';
import { FacetData, FacetGroup, configureArticleFacets, configureProductFacets, getQuery } from './Facets';
import { PaginationType, Store } from './types';

export function getSearchData(
    searchType: 'product' | 'content',
    contentSearchResult: ContentSearchResponse,
    productSearchResult: ProductSearchResponse | undefined,
    favouriteStore: Store | undefined,
): SearchData {
    if (!productSearchResult?.pagination.totalResults) {
        return {
            searchType: 'content',
            contentSearchResult: configureArticleFacets(contentSearchResult),
            currentQuery: getQuery(contentSearchResult.currentQuery),
            currentSearchTerm: contentSearchResult.freeTextSearch,
            ...contentSearchResult.pagination,
        };
    } else if (!contentSearchResult?.pagination.totalResults) {
        return {
            searchType: 'product',
            productSearchResult: configureProductFacets(productSearchResult, favouriteStore),
            contentSearchResult: configureArticleFacets(contentSearchResult),
            currentQuery: getQuery(productSearchResult.currentQuery),
            currentSearchTerm: productSearchResult.freeTextSearch,
            ...productSearchResult.pagination,
        };
    } else {
        const { currentQuery, pagination, freeTextSearch } = searchType === 'content' ? contentSearchResult : productSearchResult;
        return {
            searchType,
            contentSearchResult: configureArticleFacets(contentSearchResult),
            productSearchResult: configureProductFacets(productSearchResult, favouriteStore),
            currentQuery: getQuery(currentQuery),
            currentSearchTerm: freeTextSearch,
            ...pagination,
        };
    }
}

export function handleScroll(SCROLL_POS_KEY: string) {
    // Handling going back from product page and keeping scroll position
    const fromBackButton = performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD;
    if (fromBackButton) scrollTo(0, parseInt(sessionStorage.getItem(SCROLL_POS_KEY) ?? '0') || 0);
    sessionStorage.removeItem(SCROLL_POS_KEY);
}

export const updateHistory = (
    queryParams: QueryParams,
    replaceHistory: boolean,
    contentSearchResult: ContentSearchResult | undefined,
    excludeSearchType: boolean,
) => {
    //TODO: handle :articleTags:blabla on ArticleTagSearchPage

    const params = new URLSearchParams(queryParams as any);
    params.delete('fields');
    params.delete('pageSize');
    params.delete('press');
    if (queryParams.currentPage === '0') params.delete('currentPage');
    if (excludeSearchType) params.delete('searchType');

    const queryString = params.toString();

    const queryIsJustSorting = contentSearchResult?.sorts.some(({ code }) => queryString === `q=:${code}`);
    //add query in history only if more than just the sort is present in order to keep prettier urls
    if (!queryIsJustSorting) {
        setUrl(queryString, replaceHistory);
    }
};

interface SearchMetaData extends PaginationType {
    currentQuery: string;
    currentSearchTerm: string;
}

export type SearchData = SearchMetaData &
    (
        | {
              searchType: 'content';
              contentSearchResult: ContentSearchResult;
              productSearchResult?: ProductSearchResult;
          }
        | {
              searchType: 'product';
              contentSearchResult?: ContentSearchResult;
              productSearchResult: ProductSearchResult;
          }
    );

export interface ProductSearchResult {
    pagination: PaginationType;
    broadcastMessage: string;
    searchPageInfo: string;
    products: ProductCardDetails[];
    facets: FacetData;
    freeTextSearch: string;
    spellingSuggestion: { query: string; suggestion: string };
    sorts: SortValue[];
}

export interface ContentSearchResult {
    freeTextSearch: string;
    sorts: SortValue[];
    pagination: PaginationType;
    results: Article[];
    facets: FacetData;
}

export interface QueryParams {
    fields: 'FULL';
    pageSize: '24';
    searchType: 'product' | 'content';
    currentPage: string;
    q: string;
    latitude?: string;
    longitude?: string;
}

export const getPageTitle = (freeTextSearch: string | undefined, pageTitle: string) => {
    if (freeTextSearch) {
        return `${pageTitle} «${freeTextSearch}»`;
    }
    return pageTitle;
};

export function isFacetGroup(f: boolean | FacetGroup): f is FacetGroup {
    return typeof f !== 'boolean';
}

export function findGroupedFacet(data: SearchData, code: string): undefined | FacetGroup {
    return data.productSearchResult?.facets?.groupedFacets.find((f) => code === f.code);
}
