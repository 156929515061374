import { useContext, useEffect, useState } from 'react';
import { UserData } from '../api/product';
import { ProductInList, addToWishlist, getProductInWishlists, removeFromWishlist } from '../api/wishlist';
import CheckBox from '../generic/CheckBox';
import ErrorBox from '../generic/ErrorBox';
import Modal from '../generic/Modal';
import Spinner from '../generic/Spinner';
import { TrackingContext } from '../generic/TrackingContext';
import { ProductCardDetails } from '../product/ProductCard';
import ProductMiniCard from '../product/ProductMiniCard';
import eventTargetChecked from '../utils/eventTargetChecked';
import useBooleanState from '../utils/useBooleanState';
import ProductListForm from './ProductListForm';

interface Props {
    product: ProductCardDetails;
    onClose(): void;
    onUserDataChange(userData: UserData): void;
}

export default function ProductListModal({ product, onClose, onUserDataChange }: Props) {
    const [lists, setLists] = useState<ProductInList[]>([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useBooleanState(true);
    const { item_id, index, item_list_id, item_list_name } = useContext(TrackingContext);

    useEffect(() => {
        setIsLoading.toTrue();
        getProductInWishlists(product.code).then(setLists).catch(setError).finally(setIsLoading.toFalse);
    }, []);

    const addToList = async (listId: string) => {
        setError(null);
        setIsLoading.toTrue();
        try {
            const userData = await addToWishlist(listId, product.code);
            onUserDataChange(userData);
            changeListState(listId, true);
            gtag('event', 'add_to_wishlist', {
                wishlist_type: 'list',
                items: [{ item_id, index }],
                item_list_id,
                item_list_name,
            });
        } catch (e: any) {
            setError(e);
        } finally {
            setIsLoading.toFalse();
        }
    };

    const removeFromList = (listId: string) => {
        setIsLoading.toTrue();
        setError(null);
        removeFromWishlist(listId, product.code)
            .then(onUserDataChange)
            .then(() => changeListState(listId, false))
            .catch(setError)
            .finally(setIsLoading.toFalse);
    };

    const onListCreated = ({ code, name }: ProductInList) => {
        const newLists = [
            ...lists,
            {
                code,
                name,
                inList: false,
            },
        ];
        newLists.sort(listSorter);
        setLists(newLists);
        addToList(code);
    };

    const changeListState = (listCode: string, inList: boolean) => {
        setLists(
            editWhere(
                (list) => list.code === listCode,
                (list) => ({ ...list, inList }),
            ),
        );
    };

    return (
        <Modal onClose={onClose} isOpen size="large">
            <div className="dialog-split">
                <ProductMiniCard product={product} className="dialog-info" />
                <div className="my-list dialog-main">
                    <h1>Dine lister</h1>
                    <ErrorBox errors={error} />
                    <Spinner isSpinning={isLoading} />
                    <ul className="list-unstyled">
                        {lists.map((list) => (
                            <li key={'list-' + list.code}>
                                <CheckBox
                                    id={'list-' + list.code}
                                    isChecked={list.inList}
                                    label={list.name}
                                    onChange={eventTargetChecked((checked) => (checked ? addToList(list.code) : removeFromList(list.code)))}
                                />
                            </li>
                        ))}
                    </ul>
                    <ProductListForm onCreateList={onListCreated} />
                </div>
            </div>
        </Modal>
    );
}

function editWhere<T>(isCurrentList: (list: T) => boolean, editList: (list: T) => T): (lists: T[]) => T[] {
    return (lists) => lists.map((list) => (isCurrentList(list) ? editList(list) : list));
}

const listSorter = (a: ProductInList, b: ProductInList) => a.name.localeCompare(b.name, 'no', { sensitivity: 'base' });
